import { PureComponent } from "react";
import classnames from "classnames";
import Select from "react-select";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { withGenericConfig } from "contexts/generic-config-context";
import { LAYER_ONE } from "constants/z-index";
import { PERIOD_TEXTS } from "constants/pricing";
import { getPeriods } from "services/pricing/periods-service";
import { getMaxFreeUsersFromClusterConfig } from "services/pricing/discounts-service";
import { storeNumberOfSeats } from "services/sales-enrichment/sales-enrichment-service";
import {
  usersAndPeriodSelectionComponent,
  pricingPeriods,
  periodItem
} from "./users-and-periods-picker-component.scss";

class UsersAndPeriodsPickerComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  onUpdateSelectedBillingPeriod = (event, periodName) => {
    const { updateSelectedBillingPeriodCallBack } = this.props;
    updateSelectedBillingPeriodCallBack(periodName);
  };

  renderPeriodItem = (periodName, isSelected) => {
    const { translate } = this.props;
    return (
      <div
        className="period-item-container"
        tabIndex={0}
        onClick={(event) => this.onUpdateSelectedBillingPeriod(event, periodName)}
        onKeyDown={generateOnKeyDownHandler(this.onUpdateSelectedBillingPeriod, periodName)}
        role="radio"
        aria-checked={isSelected}
        key={periodName}
      >
        <div
          key={periodName}
          className={classnames("period-item", { selected: isSelected })}
          tabIndex={-1} // the content itself isn't tab-accessible, but its parent is
        >
          {translate(PERIOD_TEXTS[periodName].displayName)}
        </div>
        <style jsx>{periodItem}</style>
      </div>
    );
  };

  renderPeriodsPicker = () => {
    const { selectedPeriod, translate, cookies, abTests, forcePricing, ignoreClusterConfig } = this.props;
    const { clusterConfig } = ignoreClusterConfig ? { clusterConfig: {} } : this.props;
    const periods = getPeriods(clusterConfig, cookies, abTests, forcePricing);
    if (periods.length === 1) return null;
    return (
      <div className="payments-pricing-periods-wrapper" role="region">
        <div className="payments-pricing-periods" role="radiogroup" aria-label={translate("pricingPage.periods.title")}>
          {periods.map((periodName) => this.renderPeriodItem(periodName, periodName === selectedPeriod))}
        </div>
        <style jsx>{pricingPeriods}</style>
      </div>
    );
  };

  getUsersOptionLabel = (usersCount) => {
    const { translateOrTranslateWithParam, cookies, abTests, forcePricing, ignoreClusterConfig } = this.props;
    const { clusterConfig } = ignoreClusterConfig ? { clusterConfig: {} } : this.props;
    const freeUsersCount = getMaxFreeUsersFromClusterConfig(clusterConfig, abTests, cookies, forcePricing);
    if (freeUsersCount > 0 && usersCount <= freeUsersCount) {
      return translateOrTranslateWithParam({
        key: "pricingPage.seats.underXFree",
        param: freeUsersCount
      });
    }
    return `${usersCount} ${translateOrTranslateWithParam("pricingPage.seats")}`;
  };

  getUsersSelectOptions = () => {
    const { usersCountArray } = this.props;
    return usersCountArray.map((usersCount) => {
      return {
        value: usersCount,
        label: this.getUsersOptionLabel(usersCount)
      };
    });
  };

  updateSelectedUserCount = ({ value }) => {
    const { updateSelectedUserCountCallback } = this.props;
    storeNumberOfSeats(value);
    updateSelectedUserCountCallback(value);
  };

  renderUsersCountSelect = () => {
    const { selectedUserCount, translate } = this.props;
    const selectedOption = {
      value: selectedUserCount,
      label: this.getUsersOptionLabel(selectedUserCount)
    };
    return (
      <span className="users-select-container">
        <span className="users-select-label" id="usersSelectLabel">
          {translate("pricingPage.chooseTeamSize")}
        </span>
        <Select
          name="billing-users-selection"
          className="users-select-component"
          isClearable={false}
          isSearchable={false}
          value={selectedOption}
          onChange={this.updateSelectedUserCount}
          options={this.getUsersSelectOptions()}
          aria-labelledby="usersSelectLabel"
          styles={{
            option: (base) => {
              return {
                ...base,
                zIndex: LAYER_ONE
              };
            }
          }}
        />
        <style jsx>{usersAndPeriodSelectionComponent}</style>
      </span>
    );
  };

  render() {
    return (
      <div className="users-and-period-picker-container">
        {this.renderUsersCountSelect()}
        {this.renderPeriodsPicker()}
        <style jsx>{usersAndPeriodSelectionComponent}</style>
      </div>
    );
  }
}

export default withGenericConfig(UsersAndPeriodsPickerComponent);
