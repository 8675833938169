import { ProductFeaturesTableFeature } from "./product-features-table-feature/product-features-table-feature";
import { productFeaturesTableSection } from "./product-features-table-section.scss";
import { translateProductKeyFallbackToCore } from "/services/pricing-features-service";

export const ProductFeaturesTableSection = ({ productName, productSection, hideFreeTier }) => {
  const { sectionName, features } = productSection;
  const productSectionName = translateProductKeyFallbackToCore({ productName, key: `sections.${sectionName}` });
  return (
    <>
      {sectionName && (
        <div className="feature-section-name">
          <h3>{productSectionName}</h3>
        </div>
      )}
      {features.map((feature) => (
        <ProductFeaturesTableFeature
          key={feature.featureName}
          productName={productName}
          feature={feature}
          hideFreeTier={hideFreeTier}
        />
      ))}
      <style jsx>{productFeaturesTableSection}</style>
    </>
  );
};
