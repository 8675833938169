import { TIERS } from "constants/pricing";

export default {
  userCount: "3",
  signupButtonText: "Try for free",
  contactUsButtonText: "Contact us",
  plan: {
    tier: TIERS.BASIC
  },
  placement: "prices-and-features-card"
};
