import css from "styled-jsx/css";
import colors from "styles/colors";
import { TIERS_COLORS_MAP } from "services/pricing/plans-service";
import { TIERS } from "constants/pricing";
import { TIER_CARD_PRICE_HEIGHT, CLEAN_FIRST_FOLD_MARGIN } from "constants/pricing-sizes";
import { MD, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const freeTierTotalPrice = css`
  .price-card-free-tier-total-price {
    margin: 24px 0 12px;
    font-size: 0.8125rem;
    color: ${TIERS_COLORS_MAP[TIERS.FREE].tierContentColor};
  }
`;

export const tierName = css`
  .price-card-tier-name {
    margin: 24px 0 12px;
    padding-left: ${CLEAN_FIRST_FOLD_MARGIN};
    position: relative;
    width: 100%;
    display: flex;
    height: 24px;
    align-items: center;

    .tier-name-text {
      font-size: 1.238rem;
      line-height: 22px;
      font-weight: bold;
      letter-spacing: -0.361538px;
      margin-right: 8px;
      display: inline;
    }

    .tier-name-extra {
      font-size: 0.875rem;
      position: relative;
      margin-left: 8px;
      display: flex;
      height: fit-content;
      align-items: center;
      margin-right: 4px;

      .arrow {
        display: inline-block;
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 10px solid;
        top: 50%;
        transform: translateY(-50%);
        border-right-color: ${TIERS_COLORS_MAP[TIERS.FREE].tierContentColor};
        position: absolute;
        right: 100%;
      }

      .extra-text {
        color: ${colors.white};
        background-color: ${TIERS_COLORS_MAP[TIERS.FREE].tierContentColor};
        padding: 6px 8px;
        border-radius: 4px;
        font-weight: 500;
        flex: 0 0;
        word-break: keep-all;
      }

      @media screen and (max-width: ${`${MD}px`}) {
        .extra-text {
          margin-left: 4px;
          font-size: 0.8125rem;
        }
      }

      &.with-square-top-text {
        font-size: 0.8125rem;
        line-height: 1rem;
        margin-left: 4px;
        .extra-text {
          padding: 8px;
        }
      }
    }
  }
`;

export const totalPrice = css`
  .price-card-total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 18px;
    width: 100%;
    margin-top: auto;
    margin-bottom: 16px;

    .total-price-text {
      display: inline;
      text-align: left;

      .value {
        font-weight: 600;
        margin-right: 8px;
        font-size: 0.875rem;
        margin-top: 16px;
      }
      .information {
        color: ${colors.asphalt};
        margin-top: -10px;
        height: 24px;

        :global(svg) {
          width: 16px;
          height: 16px;
          pointer-events: none;
        }

        :global(path) {
          fill: ${colors.asphalt};
        }
      }
    }

    .billing-message {
      font-size: 0.8125rem;
      color: ${colors.mud};
    }
  }
`;

export const pricePerMonth = css`
  .price-card-price-per-month {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .plan-price {
      height: 50px;
      font-size: 2.7rem;
      margin-right: 8px;
      font-weight: 300;
      flex-shrink: 0;
    }

    .per-user-per-month {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      white-space: nowrap;
      text-align: left;

      & > span {
        font-weight: 100;
      }
    }
  }
`;

export const priceWithoutDiscountStyle = css`
  .plan-price-without-discount {
    .plan-price-without-discount-inner {
      position: relative;
      font-weight: bold;
      font-size: 0.875rem;
      display: inline;
      &:after {
        border-bottom: 0.12em solid;
        content: "";
        position: absolute;
        left: -1px;
        right: -1px;
        top: 50%;
      }
    }
  }
`;

export const tierFeature = css`
  .price-card-tier-feature {
    display: flex;
    justify-content: space-between;
    margin: 0 10% 14px;
    gap: 5px;

    .tier-feature-name {
      font-size: 0.844rem;
      line-height: 22px;
      flex: 1;
    }

    .tier-feature-description {
      margin-top: 4px;
      margin-left: 4px;
    }
  }
`;

export const priceAndFeaturesCard = css`
  .price-and-features-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    margin: 8px 8px;
    border-radius: 4px;
    min-height: 770px;
    width: 24%;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.chosen {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.166242);
    }

    .tier-color-bar {
      width: 100%;
      height: 10px;
      border-radius: 4px 4px 0 0;
    }

    .price-card-price-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .pricing-section {
        height: ${`${TIER_CARD_PRICE_HEIGHT}px`};
        margin-left: ${CLEAN_FIRST_FOLD_MARGIN};
        display: flex;
        flex-direction: column;
      }
      .try-cta {
        margin-left: ${CLEAN_FIRST_FOLD_MARGIN};

        &.try-cta-padding {
          padding-bottom: 30px;
        }
      }

      .pricing-signup-purchase-now {
        display: flex;
        gap: 5px;
        margin-top: 10px;
        margin-left: ${CLEAN_FIRST_FOLD_MARGIN};
        height: 20px;
      }

      .purchase-now-link {
        color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
        font-weight: 500;
        text-decoration-line: underline;
      }

      .tier-description {
        font-size: 0.875rem;
        font-weight: 200;
        line-height: 156%;
        color: #676869;
        height: 90px;
        display: flex;
        align-items: flex-start;

        text-align: left;
        padding: 0;
        margin-top: 24px;
        margin-bottom: 32px;
        margin-left: ${CLEAN_FIRST_FOLD_MARGIN};
        margin-right: ${CLEAN_FIRST_FOLD_MARGIN};

        &.with-more-plans {
          // larger spacing needed from the separator, due to the smaller width for each tier.
          margin-bottom: 64px;
        }
      }
    }
    .separator {
      width: 80%;
      margin: 0 10% 10%;
      border: 1px solid #d5d5d5;
    }

    @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
      .try-cta {
        max-width: 80%;
      }
      .separator.with-added-margin {
        margin-top: 16px;
      }
    }

    .tier-includes-container {
      .tier-includes-title {
        margin-bottom: 16px;
        padding: 0 10%;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 22px;
        margin-top: unset;
      }

      @media only screen and (min-width: 990px) and (max-width: 1110px) {
        .tier-includes-title {
          font-size: 0.875rem;
        }
      }

      @media only screen and (min-width: 1111px) and (max-width: 1280px) {
        .tier-includes-title {
          font-size: 1rem;
        }
      }

      @media only screen and (min-width: 1281px) and (max-width: 1510px) {
        .tier-includes-title {
          font-size: 1.069rem;
        }
      }

      .tier-includes-list {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
