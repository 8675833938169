import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .features-table-component {
    display: flex;
    flex-direction: column;

    .see-complete-feature-list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px;
      cursor: pointer;

      .see-complete-feature-list-button {
        margin-left: 16px;
        margin-top: 0;
        transition: transform 0.3s, margin 0.1s;
        transition-timing-function: ease-in-out;
        transform: rotate(180deg);
        :global(svg) {
          width: 24px;
          height: 24px;
        }
      }
      &.list-hidden {
        .see-complete-feature-list-button {
          transform: rotate(0);
          margin-top: 8px;
        }
      }
    }
  }
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    /* This table is not meant to be shown on smaller screens. 
            Until an alternative display is designed - if that happens - we just hide it altogether. */
    .features-table-component {
      display: none;
    }
  }
`;
