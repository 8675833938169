import PictureComponent from "components/core/picture/picture-component";

const productNameToLogoMap = {
  core:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/work_management/desktop_open_monday_wm_logo.png",
  projectManagement:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/projects/desktop_open_monday_projects_logo.png",
  software:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/dev/desktop_open_monday_dev_logo.png",
  marketing:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/marketer/desktop_open_monday_marketer_logo.png",
  crm:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/crm/desktop_open_monday_crm_logo.png"
};

const ProductLogo = ({ product }) => {
  const logoPath = productNameToLogoMap[product];
  return <PictureComponent src={logoPath} alt={product} />;
};

export default ProductLogo;