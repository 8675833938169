import { FIELD_TYPES } from "constants/template-field-type";
import productPricingConfig from "segments/desktop/pricing/product-pricing-config";
import tierDescriptionConfig from "./tier-descriptions-config";
import tierFeaturesConfig from "./tier-features-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Prices And Features Cards",
  fields: {
    textNote: {
      type: FIELD_TYPES.CONSTANT,
      label: "Note about texts:",
      text: "You can either use translation keys for translatable text, or enter English text directly, which will be untranslated."
    },
    clusterNote: {
      type: FIELD_TYPES.CONSTANT,
      label: "Note about pricing data:",
      text: "The pricing data - tier descriptions and features lists - are taken from the clusters app, if a cluster is defined for the page, or from preexisting default values."
    },
    signupButtonText: {
      type: FIELD_TYPES.TEXT,
      label: "Signup button CTA text",
      translatable: true
    },
    contactUsButtonText: {
      type: FIELD_TYPES.TEXT,
      label: "'Contact Us' button CTA text",
      translatable: true
    },
    productName: {
      label: "Product",
      type: "combo",
      options: Object.keys(productPricingConfig).map((productName) => ({
        value: productName,
        text: productName
      }))
    },
    hideFreeForever: {
      label: "Hide free forever",
      type: FIELD_TYPES.BOOLEAN
    },
    hideFreeTier: {
      label: "Hide free tier",
      type: FIELD_TYPES.BOOLEAN
    },
    showFeatureCheckbox: {
      label: "Show checkboxes on features",
      type: FIELD_TYPES.BOOLEAN
    },
    tierDescriptionConfig,
    tierFeaturesConfig
  }
};
