export const CoreIcon = ({ id }) => (
  <svg width="101" height="85" viewBox="0 0 101 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.35879 81.3048C0.226376 77.0808 -1.26399 68.7664 3.02997 62.7339L39.2728 11.8175C41.9261 8.08999 46.1671 6.10598 50.4675 6.1306C54.7616 6.11159 58.9945 8.09526 61.6441 11.8176L97.8966 62.7475C102.191 68.7799 100.7 77.0943 94.5678 81.3183C88.4353 85.5423 79.9831 84.0762 75.6891 78.0438L50.4585 42.598L25.2374 78.0302C20.9435 84.0627 12.4912 85.5287 6.35879 81.3048Z"
      fill="#6C6CFF"
    />
    <path
      d="M6.3699 3.94361C0.237483 8.16757 -1.25288 16.482 3.04108 22.5145L39.2683 73.4088C41.9124 77.1235 46.1332 79.1066 50.4183 79.096C54.729 79.1301 58.9837 77.1455 61.6432 73.4092L97.8707 22.5145C102.165 16.482 100.674 8.16757 94.5418 3.94361C88.4094 -0.280355 79.9572 1.18571 75.6632 7.21816L50.4559 42.6311L25.2485 7.21815C20.9546 1.1857 12.5023 -0.280358 6.3699 3.94361Z"
      fill={`url(#paint0_linear_2481_123991-${id})`}
    />
    <path d="M33.902 19.373L17.3531 42.622L33.9021 65.871L50.4509 42.622L33.902 19.373Z" fill="#7E7EFF" />
    <defs>
      <linearGradient
        id={`paint0_linear_2481_123991-${id}`}
        x1="58.2472"
        y1="40.3137"
        x2="54.0215"
        y2="80.4503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8F8FFF" />
        <stop offset="1" stopColor="#BABAFD" />
      </linearGradient>
    </defs>
  </svg>
);
