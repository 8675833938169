import dynamic from "next/dynamic";

const FeaturesTablePricingComponent = require("./features-table-pricing-component").default;

import FeaturesTablePricingConfig from "./features-table-pricing-config";
import FeaturesTablePricingAdvancedConfig from "./features-table-pricing-advanced-config";

export default {
  FeaturesTablePricingComponent,
  FeaturesTablePricingConfig,
  FeaturesTablePricingAdvancedConfig
};
