import { FIELD_TYPES } from "constants/template-field-type";

const featuresNestedConfig = {
  nestedConfig: {
    type: FIELD_TYPES.NESTED,
    fields: {
      name: {
        type: FIELD_TYPES.TEXT,
        label: "Feature name",
        translatable: true
      },
      text: {
        type: FIELD_TYPES.TEXT,
        label: "Feature description",
        translatable: true
      }
    }
  }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Tier features configuration",
  fields: {
    free: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: `Free tier features`,
      ...featuresNestedConfig
    },
    basic: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: `Basic tier features`,
      ...featuresNestedConfig
    },
    standard: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: `Standard tier features`,
      ...featuresNestedConfig
    },
    pro: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: `Pro tier features`,
      ...featuresNestedConfig
    },
    enterprise: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: `Enterprise tier features`,
      ...featuresNestedConfig
    }
  }
};
