import { useContext, useMemo } from "react";
import { GenericConfigContext } from "/contexts/generic-config-context";
import { getCurrencyName, getCurrencyWithPriceDisplay } from "/services/pricing-service";
import { getFreeTierSeatsCountByAbTest } from "services/ab-tests/monetization-ab-tests-service";
import { productPrices } from "services/pricing/product-prices";
import { TIERS } from "constants/pricing";
import { handleRuntimeError } from "client/services/exceptions-handling-service/exceptions-handling-service";
import { productPrice, productTotalPrice } from "./product-price.scss";

export const ProductPrice = ({ period, seats, tierName, product, hideFreeForever }) => {
  const {
    translate,
    translateOrTranslateWithParam,
    currency: currencyFromContext,
    pricingVersion,
    localeId,
    forceCurrency,
    cloudfrontViewerCountry,
    cookies,
    forcePricing,
    abTests
  } = useContext(GenericConfigContext);
  const currency = currencyFromContext || getCurrencyName({ forceCurrency, countryCode: cloudfrontViewerCountry });
  const productPricePerMonth = productPrices[product][pricingVersion][period][tierName][currency];
  const corePricePerMonth = useMemo(
    () => productPrices.core[pricingVersion][period][tierName][currency],
    [period, tierName, pricingVersion, currency]
  );
  const totalPricePerMonth = product === "core" ? productPricePerMonth : productPricePerMonth + corePricePerMonth;
  if (totalPricePerMonth === undefined) {
    const params = {
      product,
      period,
      currency,
      tierName,
      seats
    };
    handleRuntimeError(`ProductPrice: error calculating total price per month. Params: ${JSON.stringify(params)}`);
  }

  const pricePerSeat = getCurrencyWithPriceDisplay({
    price: totalPricePerMonth.toLocaleString(),
    currency,
    localeId
  });

  const totalPrice = getCurrencyWithPriceDisplay({
    price: (totalPricePerMonth * seats).toLocaleString(),
    currency,
    localeId,
    className: "product-price-total"
  });

  const renderUpToSeats = () => {
    return (
      <div className="product-price-total">
        {translateOrTranslateWithParam({
          key: "pricingPage.upToXSeats",
          param: getFreeTierSeatsCountByAbTest({ cookies, abTests, forcePricing })
        })}
      </div>
    );
  };

  const renderPricePerMonth = () => {
    return (
      <>
        <div className="product-price-total-wrapper">
          <span className="product-price-total">{translate("pricingPage.total")}</span>
          <span className="product-price-total">{totalPrice}</span>
          <span className="product-price-total">{translate("pricingPage.perMonthSlash")}</span>
        </div>
        <div className="product-price-billed-periodically">{translate(`pricingPage.periods.${period}.billed`)}</div>
      </>
    );
  };
  const renderFreeForever = () => {
    return (
      <>
        {translate("pricingPage.free")}
        <br /> {translate("pricingPage.forever")}
      </>
    );
  };
  const renderSeatPerMonth = () => {
    return (
      <>
        {translate("pricingPage.seatOver")}
        <br /> {translate("pricingPage.month")}
      </>
    );
  };
  const shouldSeeFreeForever = useMemo(() => {
    return tierName === TIERS.FREE && !hideFreeForever;
  }, [hideFreeForever, tierName]);

  return (
    <div className="price">
      <div className="price-tag">
        <div className="amount">{pricePerSeat}</div>
        <div className="price-description">
          {shouldSeeFreeForever && renderFreeForever()}
          {!shouldSeeFreeForever && renderSeatPerMonth()}
        </div>
      </div>
      {tierName === TIERS.FREE && renderUpToSeats()}
      {tierName !== TIERS.FREE && renderPricePerMonth()}
      <div />
      <style jsx>{productPrice}</style>
      <style jsx>{productTotalPrice}</style>
    </div>
  );
};
