import css from "styled-jsx/css";
import colors from "/styles/colors";
import "monday-ui-react-core/dist/DialogContentContainer.css";
import { REACT_MODAL_BACKGROUND_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .purchase-dialog-wrapper {
    position: absolute;
    display: flex;
    width: inherit;
    justify-content: center;

    .overlay {
      background-color: ${colors[REACT_MODAL_BACKGROUND_COLOR_NAME]};
      opacity: 0.7;
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      filter: opacity(0.5);
      z-index: 1000;
    }

    .purchase-now-dialog {
      width: 550px;
      height: 400px;
      position: relative;
      z-index: 1001;
      align-items: center;
      display: flex;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        font-weight: 400;
        font-size: 1.688rem;
        line-height: 24px;
        text-align: center;
      }

      .subtitle {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
      }

      .progress-bar {
        margin-top: 45px;
        width: 240px;
      }
    }
  }
`;
