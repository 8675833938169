import { PureComponent } from "react";
import classNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import { scrollTo } from "/services/dom-utils-service";
import { trackEvent } from "/services/bigbrain-service";
import {
  FREQUENTLY_ASKED_QUESTION_CLICKED,
  PRICING_PAGE_FAQ_QUESTIONS_BUTTON_CLICK,
  PRICING_PAGE_FAQ_SECTION_VISIBLE
} from "/constants/bigbrain-event-types";
import FaqQuestion from "segments/desktop/core-components/faq-question/faq-question";
import { componentStyles } from "./frequently-asked-questions-pricing-component.scss";
import DefaultProps from "./default-props";
import Title from "segments/desktop/core-components/title/title";
import { isDarkTheme } from "segments/segments-service";

const NO_QUESTION_OPEN_INDEX = -1;

export default class FrequentlyAskedQuestionsPricingComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._faqSection = null;
    this.state = {
      openedQuestionIndex: NO_QUESTION_OPEN_INDEX,
      showGoToFaq: true
    };
  }

  questionClicked = (faqs, index) => {
    const { openedQuestionIndex } = this.state;
    const closingQuestion = openedQuestionIndex === index;

    if (closingQuestion) {
      //close question
      this.setState({ openedQuestionIndex: NO_QUESTION_OPEN_INDEX });
    } else {
      //open question
      this.setState({ openedQuestionIndex: index });
    }

    trackEvent(FREQUENTLY_ASKED_QUESTION_CLICKED, {
      info2: closingQuestion ? "closed" : "opened",
      info3: faqs[index] && faqs[index].question
    });
  };

  getQuestionID = (question) => {
    return `faq_question_${question}`;
  };

  getAnswerID = (question) => {
    return `faq_answer_${question}`;
  };

  scrollToFaq = () => {
    const { clusterId } = this.props;
    if (this._faqSection) {
      trackEvent(PRICING_PAGE_FAQ_QUESTIONS_BUTTON_CLICK, {
        info1: clusterId
      });
      scrollTo(this._faqSection.offsetTop - 200, 600);
    }
  };

  renderQuestions = () => {
    const { questions, translate, questionSize, answerSize, theme } = this.props;
    const { openedQuestionIndex } = this.state;
    return (
      <ul className="faq-list" ref={(el) => (this._faqSection = el)}>
        {questions.map((questionObject, index) => {
          const { question, answer } = questionObject;
          const isOpen = openedQuestionIndex === index;
          const questionId = this.getQuestionID(question);
          const answerId = this.getAnswerID(question);
          const displayedAnswer = translate(answer);
          return (
            <li className="faq-list-item" key={index}>
              <FaqQuestion
                question={question}
                answer={answer}
                index={index}
                isOpen={isOpen}
                answerId={answerId}
                questionId={questionId}
                faqQuestionClickedCallback={(index) => this.questionClicked(questions, index)}
                questionSize={questionSize}
                theme={theme}
              />

              <div
                className={classNames("faq-answer-item", answerSize, {
                  open: isOpen,
                  "dark-theme": isDarkTheme(theme)
                })}
                id={answerId}
                role="region"
                aria-labelledby={questionId}
                tabIndex={0}
                dangerouslySetInnerHTML={{ __html: displayedAnswer }}
              />
            </li>
          );
        })}
        <style jsx>{componentStyles}</style>
      </ul>
    );
  };

  renderGoToFaqButton() {
    const { translate } = this.props;
    const { showGoToFaq } = this.state;

    return (
      <div
        className={classNames("go-to-faq-with-arrow", {
          visible: showGoToFaq
        })}
        onClick={this.scrollToFaq}
      >
        <div className="go-to-faq-button">
          <div className="text">{translate("pricingPage.faq.questions")}</div>
        </div>

        <div className="down-arrow-wrapper" />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }

  onVisibilityChange = (visible, data) => {
    const { clusterId } = this.props;
    const { showGoToFaq } = this.state;

    if (visible) {
      trackEvent(PRICING_PAGE_FAQ_SECTION_VISIBLE, {
        info1: clusterId
      });
    }

    // showing goto button only if faq seciton is not visible and is below us
    const newShowGoToFaq = !visible && data.top;
    if (showGoToFaq != newShowGoToFaq) {
      this.setState({ showGoToFaq: newShowGoToFaq });
    }
  };

  render() {
    const { questions, title, translate, titleSize, titleColor } = this.props;

    const titleProps = {
      title: translate(title),
      titleSize,
      titleColor
    };

    return (
      <div className="frequently-asked-questions-pricing-component">
        <div className="frequently-asked-questions-title">
          <Title {...titleProps} />
        </div>
        {this.renderGoToFaqButton()}
        <VisibilitySensor
          minTopValue={200}
          partialVisibility={true}
          onChange={this.onVisibilityChange}
          active={true}
          scrollCheck={true}
        >
          <div className="faqs-container">{questions && this.renderQuestions()}</div>
        </VisibilitySensor>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FrequentlyAskedQuestionsPricingComponent.defaultProps = DefaultProps;
