import { trackEvent } from "services/bigbrain-service";
import { getDefaultTierDescriptions } from "constants/pricing/pricing-page/tier-descriptions";
import { PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED } from "constants/bigbrain-event-types";
import { getTierDescriptionsFromClusterConfig, isInClusterTest } from "./clusterized-pricing-page-service";

export const getTierDescriptions = (clusterId, clusterConfig, { abTests, cookies, forcePricing, hasStudentPlan }) => {
  const pricingPageConfig = clusterConfig?.payments_view?.pricing;
  if (!pricingPageConfig?.tier_descriptions || !isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return getDefaultTierDescriptions({ hasStudentPlan, abTests, cookies, forcePricing });
  }

  const clusterizedTierDescriptions = getTierDescriptionsFromClusterConfig(pricingPageConfig, clusterId, {
    abTests,
    cookies,
    forcePricing,
    hasStudentPlan
  });

  trackEvent(PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED, {
    kind: "user_experience_for_cluster",
    info1: clusterId,
    info2: "payments_view[pricing]",
    data: pricingPageConfig.tier_descriptions,
    placement: "tier_descriptions"
  });
  return clusterizedTierDescriptions;
};
