import { PureComponent } from "react";
import { shouldSeeContactUs } from "services/pricing-service";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import Button from "components/core/button/button-component";
import { XS } from "/components/core/new-button/button-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import { HP_SOURCE, CONTACT_US_BUTTON_CLICKED } from "constants/bigbrain-event-types";
import { getPricingContactUsLink } from "services/pricing/pricing-page/contact-us-link-service";
import { withAbTests } from "/contexts/ab-tests-context";
import { PLAN_INFO_SEGMENT_NAME } from "constants/pricing";
import { pricingSignupButton, pricingContactUsButton } from "./pricing-action-button.scss";
import DefaultProps from "./default-props";

class PricingActionButton extends PureComponent {
  constructor(props) {
    super(props);
  }

  redirectToSalesPage = () => {
    const { plan, userCount, placement, clusterConfig, abTests, cookies, forcePricing } = this.props;
    BigBrain("track", CONTACT_US_BUTTON_CLICKED, {
      placement,
      source: HP_SOURCE,
      info1: plan.tier,
      info2: userCount
    });
    location.href = getPricingContactUsLink(plan.tier, clusterConfig, abTests, cookies, forcePricing);
  };

  getContactUsButton = () => {
    const { contactUsButtonText } = this.props;
    return (
      <>
        <Button
          onClickCallback={this.redirectToSalesPage}
          disableAnimation={true}
          size={XS}
          color={"outline-dark-blue-inverted-hover"}
          className="pricing-contact-us-button"
        >
          {contactUsButtonText}
        </Button>
        <style jsx>{pricingContactUsButton}</style>
      </>
    );
  };

  getRegularSignupButton = () => {
    const { plan, signupButtonText, userCount, billingPeriod } = this.props;
    return (
      <>
        <SignupFormComponent
          parentSegment={PLAN_INFO_SEGMENT_NAME}
          buttonSize={XS}
          buttonColor="outline-indigo-inverted-hover"
          ctaText={signupButtonText}
          buttonSource={plan.tier}
          useCtaText={true}
          className="pricing-signup-button"
          pricingDetails={{ tier: plan?.tier, userCount, billingPeriod }}
        />
        <style jsx>{pricingSignupButton}</style>
      </>
    );
  };

  render() {
    const { plan, userCount, clusterConfig, abTests, cookies, forcePricing } = this.props;
    const { tier } = plan;
    return shouldSeeContactUs(tier, userCount, { clusterConfig, abTests, cookies, forcePricing })
      ? this.getContactUsButton()
      : this.getRegularSignupButton();
  }
}

export default withAbTests(withGenericConfig(PricingActionButton));

PricingActionButton.defaultProps = DefaultProps;
