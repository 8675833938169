export const SoftwareIcon = ({ id }) => (
  <svg width="119" height="90" viewBox="0 0 119 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M62.9584 28.3776L62.7478 1.003C60.4418 0.757362 58.1368 0.869683 55.8322 1.05961C35.3567 2.99863 19.5524 19.8669 19.9822 40.0241C20.3301 56.342 30.7516 67.0646 38.9009 72.9595C50.5026 81.3517 64.1872 84.8326 78.2477 86.3326C86.5268 87.2158 95.2503 87.6164 101.401 87.4835L108.403 87.3443C109.251 87.3274 110.04 86.9072 110.531 86.2116L118.298 75.2094C118.978 74.2455 118.955 72.9478 118.24 72.0098L110.148 61.3974C109.631 60.7187 108.824 60.3278 107.974 60.3444L100.825 60.4841C96.1453 60.5852 88.6825 60.2693 81.3778 59.49C72.288 58.5204 63.2821 56.8059 55.7332 51.3453C50.8246 47.7946 48.0775 43.8775 47.9825 39.4193C47.8684 34.0684 51.6357 29.5041 56.7944 28.2327C58.8104 27.7359 60.9655 27.7428 62.9584 28.3776Z"
      fill={`url(#paint0_linear_2481_124029-${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.1563 25.1972C59.188 26.6843 60.4256 27.8326 61.8746 28.124C67.0828 29.1715 71.0412 33.5689 71.1553 38.9198C71.2503 43.378 68.6727 47.4101 63.92 51.1695C56.5318 57.0136 47.7648 59.0411 38.6462 60.4142C31.3815 61.5082 23.939 62.1461 19.2588 62.2472L12.4367 62.3537C11.3001 62.3714 10.2987 63.1101 9.93956 64.1957L2.73425 85.9787C2.15116 87.7415 3.47466 89.5551 5.32018 89.5222L15.368 89.3432L19.8345 89.2467C25.9854 89.1138 34.6837 88.3368 42.9176 87.0969C50.4377 85.9644 59.5157 84.1779 65.7556 81.3516C65.7606 81.3496 65.7656 81.3476 65.7706 81.3456C71.4535 79.0481 76.8502 75.84 81.6581 72.0369C89.5486 65.7955 99.5035 54.6327 99.1556 38.3149C98.7233 18.0392 82.0226 1.79827 61.3155 0.918053C59.8392 0.855295 58.6637 2.09576 58.6954 3.58279L59.1563 25.1972Z"
      fill={`url(#paint1_linear_2481_124029-${id})`}
    />
    <path
      opacity="0.6"
      d="M92.6101 60.3419C89.1078 60.1817 85.2211 59.9 81.3781 59.4901C73.9049 58.6928 66.4884 57.3922 59.8917 53.9202C53.4312 57.7074 46.1579 59.2827 38.646 60.4139C34.8244 60.9894 30.9536 61.4387 27.4617 61.75C30.9609 66.548 35.1694 70.26 38.9012 72.9595C45.6432 77.8364 53.0887 81.0548 60.9026 83.1896C62.6404 82.6318 64.2775 82.0207 65.7554 81.3513L65.7704 81.3453C71.4533 79.0478 76.8499 75.8397 81.6578 72.0366C85.2716 69.1781 89.3183 65.2874 92.6101 60.3419Z"
      fill="#019152"
    />
    <defs>
      <linearGradient
        id={`paint0_linear_2481_124029-${id}`}
        x1="40.8831"
        y1="36.5278"
        x2="65.8467"
        y2="78.5871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C875" />
        <stop offset="1" stopColor="#2EAE67" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_2481_124029-${id}`}
        x1="66.8092"
        y1="42.0745"
        x2="-14.2212"
        y2="91.7448"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CA72" />
        <stop offset="1" stopColor="#C0FFE5" />
      </linearGradient>
    </defs>
  </svg>
);
