import { TIERS } from "/constants/pricing";
import isEmpty from "lodash/isEmpty";
import { isInClusterTest } from "./clusterized-pricing-page-service";

const MIN_BUCKET_SIZE_TO_RECOMMEND_PRO = 25;
const MIN_BUCKET_SIZE_TO_RECOMMEND_ENTERPRISE = 100;

export const getMostPopularTierByBucketSize = (userCount, plans) => {
  const realUserCount = parseInt(userCount);
  if (realUserCount >= MIN_BUCKET_SIZE_TO_RECOMMEND_ENTERPRISE) {
    return TIERS.ENTERPRISE;
  }
  if (!plans.find(({ tier }) => tier === TIERS.STANDARD)) {
    return TIERS.PRO;
  }

  return realUserCount >= MIN_BUCKET_SIZE_TO_RECOMMEND_PRO ? TIERS.PRO : TIERS.STANDARD;
};

const getClusterMostPopularConfig = (clusterConfig, abTests, cookies, forcePricing) => {
  const mostPopular = clusterConfig?.payments_view?.pricing?.most_popular;
  if (!isEmpty(mostPopular) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return mostPopular;
  }
  return {};
};

export const getMostPopularTierAndText = (userCount, plans, clusterConfig, abTests, cookies, forcePricing) => {
  const clusterMostPopularConfig = getClusterMostPopularConfig(clusterConfig, abTests, cookies, forcePricing);
  const {
    forced_tier: forcedTier,
    text_for_forced_tier: textForForcedTier,
    minimum_users_count: minimumUsersCount
  } = clusterMostPopularConfig;

  const mostPopularTier = forcedTier || getMostPopularTierByBucketSize(userCount, plans);

  return {
    tier: mostPopularTier,
    text: forcedTier && textForForcedTier ? textForForcedTier : "pricingPage.mostPopular",
    minimumUsersCount
  };
};
