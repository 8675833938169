import css from "styled-jsx/css";

export const productFeaturesTableSection = css`
  .feature-section-name {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(230, 233, 239);
    grid-column: 1/-1;
    h3 {
      margin: 0;
      font-weight: 500;
      letter-spacing: 0.361538px;
      font-size: 1.375rem;
    }
  }
`;
