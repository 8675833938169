import dynamic from "next/dynamic";

const FrequentlyAskedQuestionsPricingComponent = require("./frequently-asked-questions-pricing-component").default;

import FrequentlyAskedQuestionsPricingConfig from "./frequently-asked-questions-pricing-config";
import FrequentlyAskedQuestionsPricingAdvancedConfig from "./frequently-asked-questions-pricing-advanced-config";

export default {
  FrequentlyAskedQuestionsPricingComponent,
  FrequentlyAskedQuestionsPricingConfig,
  FrequentlyAskedQuestionsPricingAdvancedConfig
};
