import css from "styled-jsx/css";
import colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "constants/vp-sizes";
import { LAYER_ONE } from "constants/z-index";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css`
  .frequently-asked-questions-pricing-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH};
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 16px;

    .go-to-faq-with-arrow {
      bottom: 50px;
      right: ${`calc((100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) / 2 + 40px)`};
      cursor: pointer;
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in;
      margin-left: 32px;

      &.visible {
        z-index: ${LAYER_ONE};
        opacity: 1;
        pointer-events: initial;
      }

      &:hover {
        .go-to-faq-button {
          background-color: ${colors["white-smoke"]};
        }

        .down-arrow-wrapper {
          border-width: 0 2px 2px 0;
        }
      }

      @media (max-width: 1360px) {
        left: initial;
        right: 40px;
      }
      @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
        display: none;
      }

      .go-to-faq-button {
        height: 40px;
        padding: 0 16px;
        border-radius: 20px;
        border: 1px solid;
        border-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
        background-color: white;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
        display: flex;

        .text {
          color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
          font-size: 0.8125rem;
          margin: auto;
        }
      }

      .down-arrow-wrapper {
        box-sizing: border-box;
        height: 12px;
        width: 12px;
        border-style: solid;
        border-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }

    .faq-list {
      width: 800px;
      max-width: 100%;
      padding: 0;
      margin: 0 auto;
      box-sizing: border-box;
    }

    .faq-list-item {
      list-style: none;
      border-bottom: 1px solid #dedede;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }

    .faq-answer-item {
      font-size: 0.8125rem;
      opacity: 0;
      display: none;
      line-height: 1.6;
      &.dark-theme {
        :global(a) {
          color: #aed4fc;
        }
      }

      &.md {
        font-size: 1.125rem;
        line-height: 32px;
      }

      &.lg {
        font-size: 1.375rem;
        line-height: 40px;
      }

      &.open {
        display: block;

        margin: 40px;
        opacity: 1;
      }
    }
  }

  .frequently-asked-questions-title {
    text-align: center;
    margin-bottom: 16px;
  }
`;
