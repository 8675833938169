import css from "styled-jsx/css";
import colors from "/styles/colors";

export const componentStyles = css`
  .pricing-features-section {
    .header {
      padding: 12px 0;
      border-bottom: ${`1px solid ${colors["border-gray"]}`};

      &:focus > .section-name {
        outline: initial;
      }

      .section-name {
        outline: none;
        font-weight: 500;
        font-size: 1.375rem;
        line-height: 24px;
        letter-spacing: -0.361538px;
        margin: unset; // default styles for h3 add 1em margin
      }
    }

    .features-list {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      .feature {
        display: flex;
        .feature-name {
          font-size: 0.875rem;
          line-height: 24px;
        }
      }
    }
  }
`;
