import { useCallback, useContext, useState } from "react";
import { TIERS } from "constants/pricing";
import { GenericConfigContext } from "/contexts/generic-config-context";
import productPricingConfig from "segments/desktop/pricing/product-pricing-config";
import classnames from "classnames";
import DownArrow from "/static/svg/arrow-down.svg";
import Button from "components/core/button/button-component";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import { withAbTests } from "/contexts/ab-tests-context";
import { getPricingContactUsLink } from "services/pricing/pricing-page/contact-us-link-service";
import { getTierTranslationKey } from "services/pricing/plans-service";
import ProductLogo from "segments/desktop/pricing/product-logo/product-logo";
import { productFeaturesTable } from "./product-features-table.scss";
import { ProductFeaturesTableSection } from "./product-features-table-section/product-features-table-section";

const ProductFeaturesTable = ({
  productName: currentProductName,
  isExpanded: isExpandedProp,
  setIsExpanded: setIsExpandedProp,
  abTests,
  hideFreeTier
}) => {
  const currentProduct = productPricingConfig[currentProductName];
  const { translate, cookies, forcePricing } = useContext(GenericConfigContext);
  const [localIsExpanded, setLocalIsExpanded] = useState(false);
  const controlledMode = setIsExpandedProp !== undefined;
  const setIsExpanded = controlledMode ? setIsExpandedProp : setLocalIsExpanded;
  const isExpanded = controlledMode ? isExpandedProp : localIsExpanded;
  const toggleFeaturesList = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [setIsExpanded, isExpanded]);
  const isFeatureListEmpty = useCallback(() => {
    const product = productPricingConfig[currentProductName];
    if (!product || !product.featuresList) {
      return true;
    }
    return product.featuresList.length === 0;
  }, []);

  const getTiers = useCallback(() => {
    if (hideFreeTier) {
      return currentProduct.tiers.filter((tier) => tier.name !== TIERS.FREE);
    }
    return currentProduct.tiers;
  }, [currentProduct, hideFreeTier]);

  return (
    <div className="product-features-table" role="table">
      <div className={classnames("toggle", { expanded: isExpanded })} onClick={() => toggleFeaturesList()}>
        <h2>{translate("pricingPage.completeFeaturesList")}</h2>
        <DownArrow />
      </div>
      {isExpanded && (
        <div className="grid">
          <div className="tier-header" />
          {getTiers().map((tier) => (
            <div key={`tier-header-${tier.name}`} className="tier-header">
              <div className="tier-title">
                {translate(getTierTranslationKey(tier.name, { hasStudentPlan: false, cookies, abTests, forcePricing }))}
              </div>
              <div className="tier-cta">
                {tier.name !== TIERS.ENTERPRISE ? (
                  <SignupFormComponent buttonSize="xs" />
                ) : (
                  <Button
                    className="cta-button"
                    type="button"
                    size="xs"
                    onClickCallback={() => {
                      window.location.href = getPricingContactUsLink(abTests);
                    }}
                  >
                    {translate("footer.contactSales")}
                  </Button>
                )}
              </div>
            </div>
          ))}
          {!isFeatureListEmpty() && (
            <div className="product-section-header">
              <ProductLogo product={currentProductName} className="product-section-logo" />
            </div>
          )}
          {!isFeatureListEmpty() &&
            productPricingConfig[currentProductName]?.featuresList?.map((productSection) => (
              <ProductFeaturesTableSection
                key={productSection.sectionName}
                productSection={productSection}
                productName={currentProductName}
                hideFreeTier={hideFreeTier}
              />
            ))}
        </div>
      )}
      <style jsx>{productFeaturesTable}</style>
      <style jsx>{`
        .grid {
          grid-template-columns: ${`repeat(${getTiers().length + 1},1fr)`};
        }
        .tier-cta {
          position: sticky;
          top: 72px;
          :global(.signup-button),
          :global(.cta-button) {
            background-color: transparent;
            border: 1px solid black;
            color: black;
            letter-spacing: 0.4px;
            &:hover {
              border-color: ${currentProduct.color};
              background-color: ${currentProduct.color};
              filter: none;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default withAbTests(ProductFeaturesTable);
