import css from "styled-jsx/css";
import colors from "styles/colors";
import { TIERS_STICKY_HEADER_OFFSET } from "constants/pricing-sizes";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";

export const stickyTableHeaderComponent = css.global`
  .sticky-table-header-container {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: ${TIERS_STICKY_HEADER_OFFSET + "px"};
    transition: top 100ms ease;
    align-items: center;
    z-index: 1;
    background-color: white;

    &.with-border {
      border-bottom: ${`1px solid ${colors["border-gray"]}`};
    }

    /* fix for the minisite pricing pages upwards scrolling */
    &.scrolling-upwards-minisite {
      top: ${`${TIERS_STICKY_HEADER_OFFSET + Constants.SECONDARY_HEADER_HEIGHT}px`};
    }

    .pick-your-plan-text {
      font-weight: bold;
      font-size: 1.575rem;
      line-height: 28px;
      letter-spacing: -0.361538px;
      align-self: center;
      padding-top: 12px;
    }

    .tier-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tier-name {
        padding-top: 4px;
        font-weight: 600;
        font-size: 1rem;
      }

      .tier-button {
        padding: 12px 0;

        .signup-button-wrapper {
          button.signup-button.ladda-button {
            padding: 8px 16px;
          }
        }

        button.pricing-contact-us-button.xs.ladda-button {
          margin-top: 0;
          padding: 8px 16px;
        }
      }
    }
  }
`;
