import { PureComponent } from "react";
import { componentStyles } from "./logos-title-component.scss";
import Title from "segments/desktop/core-components/title/title";

import DefaultProps from "./default-props";

export default class LogosTitleComponent extends PureComponent {
  render() {
    const { title, titleSize, textAlign, padding } = this.props;

    return (
      <div className="logos-title-component" style={{ textAlign, padding }}>
        <Title title={title} titleSize={titleSize} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LogosTitleComponent.defaultProps = DefaultProps;
