import { PureComponent } from "react";
import {
  shouldSeeProductsPricingPage,
  shouldSeeV10PricingVersion
} from "services/ab-tests/monetization-ab-tests-service";
import { withGenericConfig } from "contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import DefaultProps from "./default-props";
import ProductPriceAndFeaturesCards from "./product-prices-and-features-cards/product-prices-and-features-cards-component";
import PriceCardsComponent from "./components/price-cards/price-cards-component";

class PricesAndFeaturesCardsPricingComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.checkIfShouldSeeProductsFeaturesTable();
  }

  componentDidUpdate() {
    this.checkIfShouldSeeProductsFeaturesTable();
  }

  checkIfShouldSeeProductsFeaturesTable() {
    const {
      productName,
      abTests,
      clusterId,
      hasStudentPlan,
      cookies,
      forcePricing,
      cloudfrontViewerCountry,
      isClusterMiniSitePage
    } = this.props;
    this.shouldSeeProductsFeaturesTable =
      productName &&
      (isClusterMiniSitePage || shouldSeeProductsPricingPage({
        abTests,
        clusterId,
        hasStudentPlan,
        cookies,
        forcePricing,
        cloudfrontViewerCountry
      }));

    // only used to emit bigbrain event
    shouldSeeV10PricingVersion({ cookies, forcePricing, abTests });
  }

  render() {
    // rendering another component because we need access to ab tests as well.
    if (this.shouldSeeProductsFeaturesTable) {
      return <ProductPriceAndFeaturesCards {...this.props} />;
    }
    return <PriceCardsComponent {...this.props} />;
  }
}

export default withAbTests(withGenericConfig(PricesAndFeaturesCardsPricingComponent));

PricesAndFeaturesCardsPricingComponent.defaultProps = DefaultProps;
