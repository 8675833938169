import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TIERS_COLORS_MAP } from "services/pricing/plans-service";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const planFeatureValue = css`
  .plan-feature-value-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
    border: none;

    position: relative;
    &.disabled {
      cursor: default;
    }

    &:last-child {
      border-right: none;
      border-color: ${colors["dark-marble"]};
    }

    .plan-feature-name {
      font-size: 0.8125rem;
      font-weight: 400;
      text-align: center;
    }

    .plan-feature-info {
      position: absolute;
      margin-left: 8px;
      left: calc(50% + 8px);
      &.with-string-value {
        position: relative;
        left: 0;
        top: 2px;
      }
      .plan-feature-info-tooltip-container {
        z-index: ${MODAL_BACKGROUND};
        display: none;
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 16px);
        transform: translateX(-50%);
        :global(.floating-tooltip) {
          position: relative;
        }
      }
      transition: opacity 0.2s ease;
      &:hover {
        .plan-feature-info-tooltip-container {
          opacity: 1;
          display: block;
        }
      }
    }

    .check-circle {
      font-size: 0.875rem;
      color: red;
      position: relative;

      &.free {
        :global(path) {
          fill: ${TIERS_COLORS_MAP["free"].tierContentColor};
        }
      }

      &.basic {
        :global(path) {
          fill: ${TIERS_COLORS_MAP["basic"].tierContentColor};
        }
      }

      &.standard {
        :global(path) {
          fill: ${TIERS_COLORS_MAP["standard"].tierContentColor};
        }
      }

      &.pro {
        :global(path) {
          fill: ${TIERS_COLORS_MAP["pro"].tierContentColor};
        }
      }

      &.enterprise {
        :global(path) {
          fill: ${TIERS_COLORS_MAP["enterprise"].tierContentColor};
        }
      }
    }
  }
`;
