import { useRef } from "react";
import classnames from "classnames";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import DownArrow from "/static/svg/arrow-down.svg";
import { componentStyles } from "./faq-question.scss";
import { isDarkTheme } from "segments/segments-service";

const FaqQuestion = (props) => {
  const itemRef = useRef(null);
  const { question, isOpen, answerId, questionId, index, translate, faqQuestionClickedCallback, questionSize, theme } =
    props;

  const itemClicked = () => {
    faqQuestionClickedCallback(index);
  };

  const removeFocusOnMouseLeave = () => {
    itemRef?.current?.blur();
  };

  const keyDownHandler = generateOnKeyDownHandler(itemClicked);
  return (
    <div
      className="faq-question"
      onClick={itemClicked}
      onMouseLeave={removeFocusOnMouseLeave}
      aria-expanded={isOpen}
      role="button"
      onKeyDown={keyDownHandler}
      tabIndex={0}
      aria-controls={answerId}
      ref={itemRef}
    >
      <div className="faq-question-item" key={index}>
        <h3 className={classnames("faq-question-text", questionSize)} id={questionId}>
          {translate(question)}
        </h3>

        <div className={classnames("down-arrow-wrapper", { open: isOpen })} aria-hidden={true}>
          <DownArrow className={classnames("down-arrow", { white: isDarkTheme(theme) })} />
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(FaqQuestion);
