import { TIERS } from "constants/pricing";
import isEmpty from "lodash/isEmpty";
import colors from "styles/colors";
import { isInClusterTest } from "./pricing-page/clusterized-pricing-page-service";
import { trackEvent } from "../bigbrain-service";
import { PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED } from "../../constants/bigbrain-event-types";
import { applyOverrides } from "./pricing-page/clusterization/clusterized-pricing-page-helper-service";
import { isFreeTierNameFree, shouldHideBasic } from "../ab-tests/monetization-ab-tests-service";

export const TIERS_COLORS_MAP = {
  // in here, 'tierGeneralColor' is the general indicator of the tier (found at the top of the tier cards),
  // 'tierContentColor' should be the color used for displaying content colored with that tier's color, e.g. texts,
  // which require better accessibility and contrast.
  [TIERS.FREE]: {
    tierGeneralColor: colors["mud"],
    tierContentColor: colors["mud"]
  },
  [TIERS.BASIC]: {
    tierGeneralColor: colors["purple-v2"],
    tierContentColor: colors["purple-v2"]
  },
  [TIERS.STANDARD]: {
    tierGeneralColor: colors["blue"],
    tierContentColor: colors["blue"]
  },
  [TIERS.PRO]: {
    tierGeneralColor: colors["done-green"],
    tierContentColor: colors["accessible-green"]
  },
  [TIERS.ENTERPRISE]: {
    tierGeneralColor: colors["brand-enterprise-dark"],
    tierContentColor: colors["brand-enterprise-dark"]
  }
};

export const getPlanColor = (plan, isContentColored = true) => {
  const colorProperty = isContentColored ? "tierContentColor" : "tierGeneralColor";
  return TIERS_COLORS_MAP[plan.tier][colorProperty];
};

const getDefaultPlans = ({ hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing }) => {
  const basicPlan = {
    displayName: getTierTranslationKey(TIERS.BASIC),
    tier: TIERS.BASIC
  };

  const plans = [
    {
      displayName: getTierTranslationKey(TIERS.STANDARD),
      tier: TIERS.STANDARD
    },
    {
      displayName: getTierTranslationKey(TIERS.PRO),
      tier: TIERS.PRO
    },
    {
      displayName: getTierTranslationKey(TIERS.ENTERPRISE),
      tier: TIERS.ENTERPRISE
    }
  ];
  const hideBasic = shouldHideBasic({ abTests, cookies, forcePricing, hasStudentPlan });

  if (!hideBasic) {
    plans.unshift(basicPlan);
  }

  if (hasFreeTier || hasStudentPlan) {
    plans.unshift({
      displayName: getTierTranslationKey(TIERS.FREE, { hasStudentPlan, cookies, forcePricing, abTests }),
      tier: TIERS.FREE
    });
  }
  return plans;
};

export const getPlans = (options = {}) => {
  const { hasFreeTier, hasStudentPlan, clusterConfig, clusterId, abTests, cookies, forcePricing } = options;
  const plans = getDefaultPlans({ hasStudentPlan, hasFreeTier, abTests, forcePricing, cookies });

  const pricingPageConfig = clusterConfig?.payments_view?.pricing;
  const { plans: clusterPlans } = pricingPageConfig || {};

  const shouldUseHiddenPlans =
    !isEmpty(clusterPlans) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing });

  if (!shouldUseHiddenPlans) return plans;

  const { hidden_plans: hiddenPlans, plan_overrides: planOverrides = {} } = clusterPlans;

  const filteredPlans = plans
    .filter((plan) => !hiddenPlans.includes(plan.tier))
    .map((plan) => applyOverrides(plan, planOverrides[plan.tier], ["displayName"]));

  trackEvent(PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED, {
    kind: "user_experience_for_cluster",
    info1: clusterId,
    info2: "payments_view[pricing]",
    data: hiddenPlans,
    placement: "hidden_plans"
  });

  return filteredPlans;
};

export const getPreviousTierName = (currentTier, plans) => {
  const index = plans.findIndex((plan) => plan.tier === currentTier);
  return plans[index - 1]?.displayName;
};

const TIER_TRANSLATION_KEY_BY_TIER = {
  [TIERS.FREE]: "pricingPage.plans.free.title",
  [TIERS.BASIC]: "pricingPage.plans.basic.title",
  [TIERS.STANDARD]: "pricingPage.plans.standard.title",
  [TIERS.PRO]: "pricingPage.plans.pro.title",
  [TIERS.ENTERPRISE]: "pricingPage.plans.enterprise.title"
};

export const getTierTranslationKey = (tierName, { hasStudentPlan, cookies, abTests, forcePricing } = {}) => {
  if (tierName === TIERS.FREE) {
    if (hasStudentPlan) return "pricingPage.plans.student.title";
    if (isFreeTierNameFree({ cookies, abTests, forcePricing })) return "pricingPage.plans.free.title.name_free";
  }
  return TIER_TRANSLATION_KEY_BY_TIER[tierName];
};
