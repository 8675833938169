import { PureComponent } from "react";
import VisibilitySensor from "react-visibility-sensor";
import classNames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import PricingInfoIcon from "/static/svg/pricing-info-icon.svg";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import { PRICING_PAGE_FEATURE_INFO_ICON_HOVER } from "/constants/bigbrain-event-types";
import { preventFocusOnMouseDown, setSVGTitle } from "client/services/accessibility/accessibility-service";
import { getFeatureInfoElementID } from "services/pricing/pricing-page/features-service";
import { getPricingDimensions } from "/services/pricing/pricing-page/pricing-page-display-service";
import { withAbTests } from "/contexts/ab-tests-context";
import { featureComponent } from "./feature-component.scss";
import PlanFeatureValueComponent from "./plan-feature-value/plan-feature-value-component";

class FeatureComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.onMouseEnterToInfoIcon = this.onMouseEnterToInfoIcon.bind(this);
    this.state = {
      isAlreadyVisible: false,
      tooltipActive: false
    };
  }

  componentDidMount() {
    const { translateOrTranslateWithParam, featureDescription } = this.props;
    setSVGTitle(`#${this.getFeatureInfoID()}`, translateOrTranslateWithParam(featureDescription));
  }

  getFeatureInfoID = () => {
    const { featureName, indexInSection, sectionName } = this.props;
    return getFeatureInfoElementID(featureName, sectionName, indexInSection);
  };

  onMouseEnterToInfoIcon() {
    const { featureName, translate, clusterId } = this.props;
    BigBrain("track", PRICING_PAGE_FEATURE_INFO_ICON_HOVER, {
      placement: "homepage_minisite",
      kind: featureName,
      info1: translate(featureName),
      info3: clusterId
    });
  }

  shouldTrackVisibilityForFeature = () => {
    const { onVisible } = this.props;
    return !!onVisible;
  };

  onFeatureVisibilityChange = (isVisible) => {
    if (!isVisible) return;

    const { onVisible, abTests, forcePricing } = this.props;
    const { isAlreadyVisible } = this.state;

    const { callback: onVisibleCallback, onlyOnce } = onVisible;

    if (isAlreadyVisible && onlyOnce) return;
    onVisibleCallback({ abTests, forcePricing });
    this.setState({ isAlreadyVisible: true });
  };

  onTooltipFocus = () => {
    this.onMouseEnterToInfoIcon();
    this.setState({
      tooltipActive: true
    });
  };

  onTooltipBlur = () => {
    this.setState({
      tooltipActive: false
    });
  };

  render() {
    const {
      featureValuesPerPlan,
      featureName,
      featureDescription,
      plans,
      translate,
      translateOrTranslateWithParam,
      indexInSection
    } = this.props;
    const { tooltipActive } = this.state;
    const pricingDimensions = getPricingDimensions(plans);
    const { featuresSection: featuresSectionDimensions } = pricingDimensions;
    const { featureWidth, planWidth } = featuresSectionDimensions;
    const featureNameStyle = { width: `${featureWidth}%` };

    return (
      <VisibilitySensor
        partialVisibility={true}
        onChange={this.onFeatureVisibilityChange}
        active={this.shouldTrackVisibilityForFeature()}
        scrollCheck={true}
      >
        <div className="feature-component" key={featureName}>
          <div className="feature-name" onMouseDown={preventFocusOnMouseDown} role="cell" style={featureNameStyle}>
            <div className="feature-name-text">{translate(featureName)}</div>
            <div
              className={classNames("feature-info", { "tooltip-active": tooltipActive })}
              id={this.getFeatureInfoID()}
              onMouseEnter={this.onMouseEnterToInfoIcon}
              tabIndex={0}
              onFocus={this.onTooltipFocus}
              onBlur={this.onTooltipBlur}
              onMouseDown={preventFocusOnMouseDown}
              aria-label={translateOrTranslateWithParam(featureDescription)}
            >
              <PricingInfoIcon />
              <div className="feature-tooltip-container">
                <TooltipComponent theme={DARK_THEME} className="floating-tooltip">
                  {translateOrTranslateWithParam(featureDescription)}
                </TooltipComponent>
              </div>
            </div>
          </div>
          {plans.map((plan) => {
            const tier = plan.tier;
            const { value, info } = featureValuesPerPlan[tier];
            return (
              <PlanFeatureValueComponent
                key={tier}
                plan={plan}
                tier={tier}
                featureValue={value}
                featureInfo={info}
                featureName={featureName}
                indexInSection={indexInSection}
                planWidth={planWidth}
              />
            );
          })}
          <style jsx>{featureComponent}</style>
        </div>
      </VisibilitySensor>
    );
  }
}

export default withAbTests(withGenericConfig(FeatureComponent));
