import css from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const featureComponent = css`
  .feature-component {
    display: flex;
    width: 100%;

    &:first-child {
      .feature-name,
      .plan-feature-component {
        padding-top: 14px;
      }
    }

    &:last-child {
      .feature-name,
      .plan-feature-component {
        padding-bottom: 8px;
      }
    }

    .feature-name {
      display: flex;
      align-items: center;
      min-height: 48px;
      position: relative;
      padding: 0 16px;
      padding-left: 0;

      &:first-child {
        border-color: ${colors["dark-marble"]};
      }

      .feature-name-text {
        font-size: 0.844rem;
        font-weight: 400;
        line-height: 20px;
        margin: -8px 16px 0 0;
        padding: 10px 0;
        flex-grow: 1;
      }
    }
  }
  .feature-info {
    cursor: pointer;
    position: relative;

    :global(svg) {
      width: 16px;
      height: 16px;
      pointer-events: none;
    }

    :global(path) {
      fill: ${colors["asphalt"]};
    }

    .feature-tooltip-container {
      position: relative;
      z-index: ${MODAL_BACKGROUND};
      display: none;
      opacity: 0;
      position: absolute;
      bottom: calc(100% + 16px);
      transform: translateX(-50%);
      transition: opacity 0.2s ease;
      width: 300px;
      :global(.floating-tooltip) {
        position: relative;
        white-space: normal;
        text-align: left;
      }
    }

    &.tooltip-active {
      .feature-tooltip-container {
        opacity: 1;
        display: block;
      }
    }

    &:hover {
      .feature-tooltip-container {
        opacity: 1;
        display: block;
      }
    }
  }
`;
