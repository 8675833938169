import { useContext, useCallback, useState } from "react";
import { GenericConfigContext } from "/contexts/generic-config-context";
import classnames from "classnames";
import { periodSelect } from "./period-select-component.scss";

export const PeriodSelect = ({ onPeriodChange }) => {
  const [period, setPeriod] = useState("yearly");
  const { translate } = useContext(GenericConfigContext);
  const handlePeriodChange = useCallback(
    (newPeriod) => {
      setPeriod(newPeriod);
      onPeriodChange(newPeriod);
    },
    [onPeriodChange]
  );
  return (
    <div className="periodSelect">
      <div
        className={classnames("period", { selected: period === "yearly" })}
        onClick={() => {
          handlePeriodChange("yearly");
        }}
      >
        {translate("pricingPage.periods.yearly.displayNameShort")} {translate("pricingPage.periods.save")}
      </div>
      <div>|</div>
      <div
        className={classnames("period", { selected: period === "monthly" })}
        onClick={() => {
          handlePeriodChange("monthly");
        }}
      >
        {translate("pricingPage.periods.monthly.displayName")}
      </div>
      <style jsx>{periodSelect}</style>
    </div>
  );
};
