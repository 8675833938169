import css from "styled-jsx/css";

export const productPrice = css`
  .price {
    height: 140px;
  }

  .price-tag {
    margin: 8px 0 8px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .amount {
      font-size: 2.7rem;
      margin-right: 4px;
      font-weight: 600;
    }
    .price-description {
      line-height: 18px;
      font-size: 0.8125rem;
      color: #323338;
      margin-bottom: 4px;
    }
  }
`;

export const productTotalPrice = css.global`
  .product-price-total-wrapper {
    display: flex;
    gap: 5px;
  }

  .product-price-billed-periodically {
    font-size: 0.875rem;
  }

  .product-price-total {
    font-size: 0.875rem;
    font-weight: bold;
  }
`;
