import isEmpty from "lodash/isEmpty";
import { isInClusterTest } from "./clusterized-pricing-page-service";
import {shouldGetEnterpriseContactUsPageAbTest} from "services/ab-tests/acquisition-ab-test-service"

const DEFAULT_CONTACT_SALES_LINK = "/sales/contact-us?from=pricing&source=Website%20-%20Contact%20Sales";
const ENTERPRISE_CONTACT_SALES_LINK = "/sales/contact-us/enterprise?from=pricing&source=Website%20-%20Contact%20Sales";

const getClusterContactUsLinksByTier = (clusterConfig, abTests, cookies, forcePricing) => {
  const contactUsLinks = clusterConfig?.payments_view?.pricing?.contact_us_links;
  if (!isEmpty(contactUsLinks) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return contactUsLinks;
  }
  return {};
};

export const getPricingContactUsLink = (tier, clusterConfig, abTests, cookies, forcePricing) => {
  const contactUsLinksByTier = getClusterContactUsLinksByTier(clusterConfig, abTests, cookies, forcePricing);
  const { [tier]: contactUsLink } = contactUsLinksByTier;

  return contactUsLink || getDefaultContactUsPageLink(abTests);
};


export const getDefaultContactUsPageLink = (abTests, sendBigBrainEvent = true ) => {
  if (shouldGetEnterpriseContactUsPageAbTest(abTests, sendBigBrainEvent)) return ENTERPRISE_CONTACT_SALES_LINK;
  return DEFAULT_CONTACT_SALES_LINK;
}
