import { useCallback, useState } from "react";
import productPricingConfig from "segments/desktop/pricing/product-pricing-config";
import CarouselGallary from "/components/core/carousel-gallary/carousel-gallary";
import { trackEvent } from "services/bigbrain-service";
import { PRICING_PAGE_PURCHASE_NOW_CLICKED } from "constants/bigbrain-event-types";
import { storeNumberOfSeats } from "services/sales-enrichment/sales-enrichment-service";
import { TIERS } from "constants/pricing";
import ProductPriceAndFeaturesCard from "./product-price-and-features-card/product-price-and-features-card";
import { productPriceAndFeaturesCards } from "./product-prices-and-features-cards-component.scss";
import { UsersSelect } from "./users-select/users-select-component";
import { PeriodSelect } from "./period-select/period-select-component";
import PurchaseNowModal from "../components/purchase-now-modal/purchase-now-modal";

const SEATS_OPTIONS = { 3: 3, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25, 30: 30, 40: 40, 50: 50, 100: 100, "200+": 200 };

const ProductPriceAndFeaturesCards = ({ productName, hideFreeTier, hideFreeForever }) => {
  const { tiers, color, hideProductIcon, mostPopularTierBySeat } = productPricingConfig[productName];
  const { color: coreColor } = productPricingConfig.core;
  const [seats, setSeats] = useState(Object.values(SEATS_OPTIONS)[0]);
  const [period, setPeriod] = useState("yearly");
  const [purchaseNowModal, setPurchaseNowModal] = useState(false);
  const [purchaseModalTarget, setPurchaseModalTarget] = useState(null);
  const mostPopularTier = getMostPopularTierBySeats(seats, mostPopularTierBySeat);

  const onPurchaseNowClicked = ({ tier, target }) => {
    trackEvent(PRICING_PAGE_PURCHASE_NOW_CLICKED, {
      kind: productName,
      info1: tier.name,
      info2: period,
      info3: seats
    });
    setPurchaseNowModal(true);
    setPurchaseModalTarget(target);
  };

  const setSelectedSeats = (selectedSeats) => {
    storeNumberOfSeats(selectedSeats);
    setSeats(selectedSeats);
  };

  const getTiers = useCallback(() => {
    if (hideFreeTier) {
      return tiers.filter((tier) => tier.name !== TIERS.FREE);
    }
    return tiers;
  }, [tiers, hideFreeTier]);

  return (
    <div className="container">
      <div className="seats-and-period-selection">
        <UsersSelect usersCountArray={SEATS_OPTIONS} updateSelectedUserCountCallback={setSelectedSeats} />
        <PeriodSelect color={color} period={period} onPeriodChange={setPeriod} />
      </div>
      <div className="tiers">
        {getTiers().map((tier) => {
          return (
            <ProductPriceAndFeaturesCard
              period={period}
              hideProductIcon={hideProductIcon}
              productName={productName}
              tier={tier}
              color={color}
              seats={seats}
              coreColor={coreColor}
              isMostPopular={mostPopularTier === tier.name}
              onPurchaseNowClicked={onPurchaseNowClicked}
              hideFreeForever={hideFreeForever}
            />
          );
        })}
      </div>
      <div className="tiers tiers-mobile">
        <CarouselGallary
          className="carousel-item"
          autoplay={false}
          wrapAround={false}
          edgeEasing="easeBack"
          slidesToShow={1}
          slideIndex={1}
          cellAlign="center"
          cellSpacing={16}
          width="100vw"
          slideWidth={0.7}
          renderCenterLeftControls={() => {}}
          renderCenterRightControls={() => {}}
          heightMode="max"
        >
          {getTiers().map((tier) => {
            return (
              <ProductPriceAndFeaturesCard
                period={period}
                hideProductIcon={hideProductIcon}
                productName={productName}
                tier={tier}
                color={color}
                seats={seats}
                coreColor={coreColor}
                isMostPopular={mostPopularTier === tier.name}
                onPurchaseNowClicked={onPurchaseNowClicked}
              />
            );
          })}
        </CarouselGallary>
      </div>
      <PurchaseNowModal isOpen={purchaseNowModal} signupButton={purchaseModalTarget} />
      <style jsx>{productPriceAndFeaturesCards}</style>
      <style jsx>{`
        .tiers {
          grid-template-columns: ${`repeat(${getTiers().length},1fr)`};
        }
      `}</style>
    </div>
  );
};

const getMostPopularTierBySeats = (seats, mostPopularTierBySeat) => {
  const mostPopularTierBySeatKeys = Object.keys(mostPopularTierBySeat)
    .map((key) => +key)
    .sort((x, y) => x > y);
  const mostPopularTierBySeatKey = mostPopularTierBySeatKeys.reverse().find((key) => key <= seats);
  return mostPopularTierBySeat[mostPopularTierBySeatKey];
};

export default ProductPriceAndFeaturesCards;
