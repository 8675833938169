import { PureComponent } from "react";
import isString from "lodash/isString";
import classNames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import { preventFocusOnMouseDown, setSVGTitle } from "client/services/accessibility/accessibility-service";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import PricingCheck from "/static/svg/pricing-check.svg";
import PricingInfoIcon from "/static/svg/pricing-info-icon.svg";
import { planFeatureValue } from "./plan-feature-value-component.scss";

const isObject = (value) => typeof value === "object" && value !== null;

class PlanFeatureValueComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // setting the 'aria-label' value to either the cell itself, or the checkmark SVG within it.
    const { featureValue } = this.props;
    if (featureValue === true) {
      // value is a boolean true, i.e. a checkmark is rendered
      setSVGTitle(`#${this.getFeatureValueCellID()}`, this.getFeatureAriaLabel());
    } else {
      document.getElementById(this.getFeatureValueCellID()).setAttribute("aria-label", this.getFeatureAriaLabel());
    }
  }

  getFeatureValueCellID = () => {
    const { tier, featureName, indexInSection } = this.props;
    return `featureTierValue_${featureName.replace(/\./g, "_")}_${tier}_${indexInSection}`;
  };

  getBooleanValueString = (value) => {
    const { translate } = this.props;
    return value ? translate("pricingPage.included") : translate("pricingPage.notIncluded");
  };

  getFeatureAriaLabel = () => {
    const { plan, featureValue, featureName, translate } = this.props;
    const { displayName } = plan;
    const value = isString(featureValue) ? translate(featureValue) : this.getBooleanValueString(featureValue);

    return `${translate(displayName)}: ${translate(featureName)}. ${value}.`;
  };

  render() {
    const { tier, featureValue, featureInfo, translateOrTranslateWithParam, planWidth } = this.props;
    const planFeatureStyle = { width: `${planWidth}%` };
    return (
      <div
        id={this.getFeatureValueCellID()}
        className={classNames("plan-feature-value-component", tier)}
        onMouseDown={preventFocusOnMouseDown}
        role="cell"
        style={planFeatureStyle}
      >
        {featureValue === true && (
          <div className={classNames(`check-circle ${tier}`)}>
            <PricingCheck />
          </div>
        )}
        {(isString(featureValue) || isObject(featureValue)) && (
          <span className="plan-feature-name">{translateOrTranslateWithParam(featureValue)}</span>
        )}
        {featureInfo && (
          <div className={classNames("plan-feature-info", { "with-string-value": isString(featureValue) })}>
            <PricingInfoIcon />
            <div className="plan-feature-info-tooltip-container">
              <TooltipComponent theme={DARK_THEME} className="floating-tooltip">
                {translateOrTranslateWithParam(featureInfo)}
              </TooltipComponent>
            </div>
          </div>
        )}
        <style jsx>{planFeatureValue}</style>
      </div>
    );
  }
}

export default withGenericConfig(PlanFeatureValueComponent);
