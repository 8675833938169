import { PureComponent } from "react";
import classnames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import PictureComponent from "components/core/picture/picture-component";
import { componentStyles } from "./testimonial-component.scss";

class TestimonialComponent extends PureComponent {
  renderImage = () => {
    const { testimonial } = this.props;
    const { image } = testimonial;
    return (
      <div className="testimonial-image-container">
        <PictureComponent {...image} className="testimonial-image" />
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  renderMainText = () => {
    const { testimonial, translate } = this.props;
    const { testimonialText } = testimonial;
    return (
      <>
        <div className="testimonial-text" dangerouslySetInnerHTML={{ __html: translate(testimonialText) }} />
        <style jsx>{componentStyles}</style>
      </>
    );
  };

  renderPerson = () => {
    const { testimonial, translate } = this.props;
    const { personName, personRole } = testimonial;
    return (
      <div className="testimonial-person-container">
        <span className="testimonial-person-name">{translate(personName)}</span>
        <span className="separator">{translate("pricingPage.testimonials.separator")}</span>
        <span className="testimonial-person-title">{translate(personRole)}</span>
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  render() {
    const { isCurrentSlide } = this.props;
    return (
      <div className={classnames("testimonial-component", { active: isCurrentSlide })}>
        {this.renderImage()}
        <div className="testimonial-text-container">
          {this.renderMainText()}
          {this.renderPerson()}
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(TestimonialComponent);
