import { PureComponent } from "react";
import { isFunction } from "services/utils-service";
import { preventFocusOnMouseDown } from "client/services/accessibility/accessibility-service";
import { bottomCenterPoints } from "./bottom-center-points.scss";
export default class BottomCenterPoints extends PureComponent {
  constructor(props) {
    super(props);
    this.getIndexes = this.getIndexes.bind(this);
    this.getListStyles = this.getListStyles.bind(this);
    this.getListItemStyles = this.getListItemStyles.bind(this);
    this.getButtonStyles = this.getButtonStyles.bind(this);
  }

  onDotClick = (index) => {
    const { goToSlide, bottomCenterExtraProps } = this.props;
    const { onButtonClick } = bottomCenterExtraProps;
    if (isFunction(onButtonClick)) {
      onButtonClick(index);
    }
    goToSlide(index);
  };

  render() {
    let self = this;
    const { slideCount, slidesToScroll, currentSlide } = this.props;
    let indexes = this.getIndexes(slideCount, slidesToScroll);
    return (
      <ul style={this.getListStyles()}>
        {indexes.map((index) => {
          return (
            <li style={self.getListItemStyles()} key={index}>
              <button
                tabIndex={0}
                className="bottom-center-point-button"
                style={self.getButtonStyles(currentSlide === index)}
                onClick={() => this.onDotClick(index)}
                onMouseDown={preventFocusOnMouseDown}
              >
                &bull;
              </button>
            </li>
          );
        })}
        <style jsx>{bottomCenterPoints}</style>
      </ul>
    );
  }

  getIndexes(count, inc) {
    let arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }
    return arr;
  }
  getListStyles() {
    return {
      position: "relative",
      margin: 0,
      top: -10,
      padding: 0,
      width: "100vw",
      display: "flex",
      justifyContent: "center"
    };
  }
  getListItemStyles() {
    return {
      listStyleType: "none",
      display: "inline-block"
    };
  }
  getButtonStyles(active) {
    return {
      border: 0,
      background: "transparent",
      cursor: "pointer",
      padding: 10,
      outline: 0,
      fontSize: 24,
      opacity: active ? 1 : 0.5
    };
  }
}

BottomCenterPoints.defaultProps = {
  bottomCenterExtraProps: {}
};
