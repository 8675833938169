import css from "styled-jsx/css";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import { LG, LGR, MD, SM, XL, XS, XS_L, XS_M, XXL, XXS } from "constants/sizes";
import { titleFontSizes } from "segments/desktop/core-components/title/consts";

export const componentStyles = css`
  .faq-question {
    width: 100%;
    padding: 25px 0;

    &:active,
    &:hover {
      outline: none;
    }
  }

  .faq-question-item {
    margin-top: 8px;

    display: flex;
    margin-left: 16px;
    justify-content: space-between;
  }

  .faq-question-text {
    font-size: 1.125rem;
    padding-right: 8px;
    margin: unset;
    &.xss {
      font-size: ${titleFontSizes[XXS]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs_m {
      font-size: ${titleFontSizes[XS_M]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs_l {
      font-size: ${titleFontSizes[XS_L]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs {
      font-size: ${titleFontSizes[XS]};
      line-height: 32px;
      font-weight: 400;
    }

    &.sm {
      font-size: ${titleFontSizes[SM]};
      line-height: 40px;
      font-weight: 400;
    }

    &.md {
      font-size: ${titleFontSizes[MD]};
      line-height: 48px;
      font-weight: 400;
    }

    &.lg {
      font-size: ${titleFontSizes[LG]};
      line-height: 60px;
      font-weight: 700;
    }

    &.lgr {
      font-size: ${titleFontSizes[LGR]};
      line-height: 68px;
      font-weight: 700;
    }

    &.xl {
      font-size: ${titleFontSizes[XL]};
      line-height: 72px;
      font-weight: 700;
    }

    &.xxl {
      font-size: ${titleFontSizes[XXL]};
      line-height: 85px;
      font-weight: 700;
    }
  }

  .down-arrow-wrapper {
    margin-right: 16px;
    transition: transform 0.3s ease-in-out;

    :global(.down-arrow) {
      width: 16px;
      height: 16px;
    }
    :global(.down-arrow.white) {
      :global(path) {
        fill: white;
      }
    }
    &.open {
      transform: rotate(180deg);
      position: relative;
      bottom: 8px;
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .faq-question-text {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .faq-answer-item {
      font-size: 0.875rem;

      &.open {
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 80px;
      }
    }
  }
`;
