import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import { contactUsImage } from "./contact-us-image.scss";

class ContactUsImage extends PureComponent {
  getAltText = () => {
    const { translate, plan } = this.props;
    const { displayName } = plan;
    return `${translate("footer.contactUs")} - ${translate(displayName)}`;
  };

  render() {
    const { plan } = this.props;
    const { tier } = plan;
    const imgSrc = `/static/img/pricing/contact_us_plan_${tier}.png`;
    return (
      <div className="tier-card-contact-us-image-container">
        <div className={`contact-us-image-wrapper ${tier}`}>
          <img src={imgSrc} alt={this.getAltText()} />
        </div>
        <style jsx>{contactUsImage}</style>
      </div>
    );
  }
}

export default withGenericConfig(ContactUsImage);
