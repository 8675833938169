import css from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const featureInfoWithTooltipStyles = css`
  .feature-info {
    position: relative;

    :global(svg) {
      width: 16px;
      height: 16px;
      pointer-events: none;
    }

    :global(path) {
      fill: ${colors["asphalt"]};
    }

    .feature-tooltip-container {
      z-index: ${MODAL_BACKGROUND};
      display: none;
      opacity: 0;
      position: absolute;
      bottom: calc(100% + 14px);
      transform: translateX(-50%);
      transition: opacity 0.2s ease;
      width: 300px;
      :global(.floating-tooltip) {
        position: relative;
        white-space: normal;
        text-align: left;
      }

      @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
        :global(.floating-tooltip) {
          width: 60vw;
        }
      }

      &.auto-width {
        width: auto;
      }
    }

    &.tooltip-active {
      .feature-tooltip-container {
        opacity: 1;
        display: block;
      }
    }

    &:hover {
      .feature-tooltip-container {
        opacity: 1;
        display: block;
      }
    }
  }
`;
