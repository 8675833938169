import { PureComponent } from "react";
import { withAbTests } from "contexts/ab-tests-context";
import { withGenericConfig } from "contexts/generic-config-context";
import { PERIODS } from "constants/pricing";
import CarouselGallary from "/components/core/carousel-gallary/carousel-gallary";
import { getPlans } from "services/pricing/plans-service";
import { getCurrencyName } from "/services/pricing-service";
import { getPricingPageConfig } from "services/pricing/pricing-page/pricing-page-config-service";
import { getPricingModelService } from "/services/pricing/pricing-model/services";
import { getPlanValueFeatures } from "services/pricing-features-service";
import { getTierDescriptions } from "services/pricing/pricing-page/tier-descriptions-service";
import PriceAndFeaturesCard from "segments/desktop/core-components/price-and-features-card/price-and-features-card";
import { trackEvent } from "services/bigbrain-service";
import { PRICING_PAGE_PURCHASE_NOW_CLICKED } from "constants/bigbrain-event-types";
import isEmpty from "lodash/isEmpty";
import { componentStyles } from "./price-cards-component.scss";
import UsersAndPeriodsPickerComponent from "../users-and-periods-picker/users-and-periods-picker-component";
import PurchaseNowModal from "../purchase-now-modal/purchase-now-modal";

class PriceCardsComponent extends PureComponent {
  constructor(props) {
    super(props);

    const { pricingVersion, forcePricing } = props;
    const { clusterConfig } = this.getClusterProps();
    this._pricingModelService = getPricingModelService(pricingVersion);
    this.usersCountArray = this._pricingModelService.getUserOptions({
      forcePricing,
      clusterConfig,
      abTests: [],
      cookies: []
    });

    this.state = {
      userCount: this._pricingModelService.getDefaultUserOption({
        clusterConfig,
        forcePricing,
        abTests: [],
        cookies: []
      }),
      billingPeriod: PERIODS.yearly,
      purchaseNowModalOpen: false,
      purchaseModalTarget: null
    };
  }

  getCurrency = () => {
    const { currency, forceCurrency, cloudfrontViewerCountry } = this.props;
    const { pricingDefaults } = getPricingPageConfig();
    return currency || getCurrencyName({ forceCurrency, pricingDefaults, countryCode: cloudfrontViewerCountry });
  };

  updateSelectedBillingPeriodCallBack = (periodName) => {
    this.setState({
      billingPeriod: periodName
    });
  };

  updateSelectedUserCountCallback = (userCount) => {
    this.setState({
      userCount
    });
  };

  getPlanPrice = (plan, hideDiscount = false) => {
    const { pricingVersion, abTests, cookies, forcePricing } = this.props;
    const { billingPeriod, userCount } = this.state;
    const { clusterConfig } = this.getClusterProps();
    const { tier } = plan;
    const currencyValue = this.getCurrency(); // renaming to avoid collision with the context-passed value.

    return this._pricingModelService.getPlanPrice(
      tier,
      currencyValue,
      billingPeriod,
      userCount,
      pricingVersion,
      clusterConfig,
      abTests,
      cookies,
      forcePricing,
      hideDiscount
    );
  };

  getClusterProps = () => {
    const { ignoreClusterConfig } = this.props;
    const { clusterConfig, clusterId } = ignoreClusterConfig ? { clusterConfig: {}, clusterId: undefined } : this.props;
    return {
      clusterConfig,
      clusterId
    };
  };

  onPurchaseNowClicked = ({ tier, target }) => {
    const { billingPeriod: period, userCount } = this.state;
    trackEvent(PRICING_PAGE_PURCHASE_NOW_CLICKED, {
      kind: "core",
      info1: tier,
      info2: period,
      info3: userCount
    });
    this.setState({ purchaseNowModalOpen: true, purchaseModalTarget: target });
  };

  renderCards = (isMobile) => {
    const {
      hasFreeTier,
      hasStudentPlan,
      signupButtonText,
      contactUsButtonText,
      tierDescriptionConfig,
      tierFeaturesConfig,
      abTests,
      cookies,
      forcePricing,
      ignoreClusterConfig,
      showFeatureCheckbox
    } = this.props;

    const { clusterConfig, clusterId } = this.getClusterProps();
    const { userCount, billingPeriod } = this.state;
    const currencyValue = this.getCurrency();
    const params = { hasStudentPlan, hasFreeTier, abTests, cookies, clusterId, clusterConfig, forcePricing };
    const plans = getPlans(params);
    const tierDescriptions = getTierDescriptions(clusterId, clusterConfig, params);

    const getTopFeatures = (tier) => {
      const config = tierFeaturesConfig?.[tier];
      if (isEmpty(config)) {
        return getPlanValueFeatures(params)[tier];
      }
      return config;
    };

    return plans.map((plan) => {
      const { tier } = plan;
      return (
        <PriceAndFeaturesCard
          tierDescription={tierDescriptionConfig?.[tier] || tierDescriptions[tier]}
          features={getTopFeatures(tier)}
          key={tier}
          userCount={userCount}
          billingPeriod={billingPeriod}
          planPrice={this.getPlanPrice(plan)}
          planPriceWithoutDiscount={this.getPlanPrice(plan, true)}
          plan={plan}
          currencyValue={currencyValue}
          signupButtonText={signupButtonText}
          contactUsButtonText={contactUsButtonText}
          plans={plans}
          isMobile={isMobile}
          ignoreClusterConfig={ignoreClusterConfig}
          abTests={abTests}
          cookies={cookies}
          forcePricing={forcePricing}
          onPurchaseNowClicked={this.onPurchaseNowClicked}
          showFeatureCheckbox={showFeatureCheckbox}
        />
      );
    });
  };

  renderMobileCards = () => {
    return (
      <CarouselGallary
        className="carousel-item"
        autoplay={false}
        wrapAround={false}
        edgeEasing={"easeBack"}
        slidesToShow={1}
        slideIndex={1}
        cellAlign={"center"}
        cellSpacing={16}
        width="100vw"
        slideWidth={0.7}
        renderCenterLeftControls={() => {}}
        renderCenterRightControls={() => {}}
        heightMode={"max"}
      >
        {this.renderCards(true)}
      </CarouselGallary>
    );
  };

  render() {
    const { ignoreClusterConfig } = this.props;
    const { userCount, billingPeriod, purchaseNowModalOpen, purchaseModalTarget } = this.state;
    return (
      <div className="prices-and-features-cards-pricing-component">
        <div className="top-section">
          <UsersAndPeriodsPickerComponent
            updateSelectedBillingPeriodCallBack={this.updateSelectedBillingPeriodCallBack}
            updateSelectedUserCountCallback={this.updateSelectedUserCountCallback}
            selectedPeriod={billingPeriod}
            selectedUserCount={userCount}
            usersCountArray={this.usersCountArray}
            ignoreClusterConfig={ignoreClusterConfig}
          />
        </div>
        <div className="cards-section">{this.renderCards()}</div>
        <div className="mobile-price-cards">{this.renderMobileCards()}</div>
        <PurchaseNowModal isOpen={purchaseNowModalOpen} signupButton={purchaseModalTarget} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withAbTests(withGenericConfig(PriceCardsComponent));
