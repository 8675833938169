export const CRMIcon = ({ id }) => (
  <svg width="115" height="77" viewBox="0 0 115 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M76.6747 35.5205C76.7798 37.0109 75.5603 38.2236 74.0681 38.2237L41.4712 38.2242C39.979 38.2243 38.7594 39.4371 38.8645 40.9275C39.4194 48.8025 42.3882 56.3366 47.3953 62.4735C52.9727 69.3095 60.7341 73.9888 69.357 75.7141C77.9798 77.4393 86.9306 76.1038 94.6843 71.9351C102.438 67.7663 108.515 61.0223 111.879 52.8521C115.244 44.6819 115.688 35.591 113.136 27.1284C110.583 18.6658 105.193 11.3551 97.883 6.442C90.5729 1.52889 81.7953 -0.682683 73.0459 0.18412C70.4321 0.443059 67.8693 0.971922 65.3943 1.75303C62.8564 2.54731 60.4103 3.60662 58.0961 4.91196C57.9578 4.98998 57.82 5.06888 57.6826 5.14865C63.5071 8.53128 68.3677 13.4285 71.7244 19.3729L71.7349 19.2104C71.7605 19.2554 71.7861 19.3004 71.8116 19.3455C74.631 24.3384 76.2763 29.8694 76.6747 35.5205Z"
      fill="#00A0A0"
    />
    <ellipse cx="38.7706" cy="38.2245" rx="38.0015" ry="38.2245" fill="white" />
    <ellipse cx="38.7706" cy="38.2245" rx="38.0015" ry="38.2245" fill={`url(#paint0_linear_2481_124001-${id})`} />
    <path
      d="M57.7774 71.3318C69.1327 64.7217 76.7721 52.3705 76.7721 38.2245C76.8043 36.6808 76.6748 35.5205 76.6748 35.5205C76.7472 36.9999 75.5603 38.2237 74.0681 38.2237L41.4712 38.2243C39.979 38.2243 38.7594 39.4372 38.8645 40.9275C39.4194 48.8025 42.3882 56.3367 47.3953 62.4735C50.3192 66.0573 53.8435 69.0484 57.7774 71.3318Z"
      fill={`url(#paint1_linear_2481_124001-${id})`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_2481_124001-${id}`}
        x1="12.3417"
        y1="15.2784"
        x2="76.9277"
        y2="48.0951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00D2D2" />
        <stop offset="1" stopColor="#00D2D2" stopOpacity="0.29" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_2481_124001-${id}`}
        x1="80.8947"
        y1="57.9507"
        x2="20.4328"
        y2="45.4263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0A0" />
        <stop offset="1" stopColor="#00D2D2" />
      </linearGradient>
    </defs>
  </svg>
);
