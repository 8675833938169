import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const productPriceAndFeaturesCards = css`
  .container {
    width: calc(216px * 5 + 16px * 4);
    max-width: calc(100% - 32px);
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .seats-and-period-selection {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    .tiers {
      display: grid;
      justify-content: center;
      column-gap: 16px;
    }

    .tiers-mobile {
      display: none;
    }
  }
  @media only screen and (min-width: 1440px) {
    .container {
      width: calc(240px * 5 + 16px * 4);
    }
  }

  @media only screen and (min-width: 1920px) {
    .container {
      width: calc(304px * 5 + 16px * 4);
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .container {
      width: 100%;
      max-width: 100%;
      overflow-x: none;
      .tiers {
        display: none;
        &.tiers-mobile {
          display: block;
          width: 100%;
        }
      }
      .seats-and-period-selection {
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
