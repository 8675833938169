// plop_marker:layouts_import
import FeaturesTablePricing from "./features-table-pricing";
import PricesAndFeaturesCardsPricing from "./prices-and-features-cards-pricing";
import FrequentlyAskedQuestionsPricing from "./frequently-asked-questions-pricing";

export default {
  // plop_marker:layouts_add_layout
  FeaturesTablePricing,
  PricesAndFeaturesCardsPricing,
  FrequentlyAskedQuestionsPricing,
};
