import { useCallback, useContext } from "react";
import classnames from "classnames";
import PricingCheck from "/static/svg/pricing-check.svg";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import PricingInfoIcon from "/static/svg/pricing-info-icon.svg";
import productPricingConfig from "segments/desktop/pricing/product-pricing-config";
import { GenericConfigContext } from "/contexts/generic-config-context";
import { TIERS } from "constants/pricing";
import { productFeaturesTableFeature } from "./product-features-table-feature.scss";
import { translateProductKeyFallbackToCore } from "/services/pricing-features-service";

export const ProductFeaturesTableFeature = ({ productName, feature, hideFreeTier }) => {
  const { featureName, tierColumnValues } = feature;
  const { translate } = useContext(GenericConfigContext);
  const product = productPricingConfig[productName];
  const productFeatureName = translateProductKeyFallbackToCore({ productName, key: featureName });
  const productFeatureDescription = translateProductKeyFallbackToCore({
    productName,
    key: `${featureName}.description`
  });

  const getProductTiers = useCallback(() => {
    if (hideFreeTier) {
      return product.tiers.filter((tier) => tier.name !== TIERS.FREE);
    }
    return product.tiers;
  }, [hideFreeTier, product]);

  return (
    <>
      <div className="feature-name">
        {productFeatureName}
        <div className="icon">
          <div className="tooltip">
            <TooltipComponent theme={DARK_THEME} className="floating-tooltip">
              {" "}
              {productFeatureDescription}
            </TooltipComponent>
          </div>
          <PricingInfoIcon />
        </div>
      </div>
      {getProductTiers().map((tier) => {
        const value = tierColumnValues[tier.name];
        if (value === true) {
          return (
            <div className={classnames("feature-value", "check-circle")}>
              <PricingCheck />
            </div>
          );
        }
        if (value === undefined || value === null) {
          return <div />;
        }
        return <div className="feature-value">{translate(value)}</div>;
      })}
      <style jsx>{productFeaturesTableFeature}</style>
    </>
  );
};
