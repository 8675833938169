import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import "monday-ui-react-core/dist/LinearProgressBar.css";

const LinearProgressBar = dynamic(() => import("monday-ui-react-core/dist/LinearProgressBar"), {
  ssr: false
});

const ProgressBarIntervalComponent = ({ maxValue }) => {
  let timer;
  const [count, setCount] = useState(1);

  const updateCount = () => {
    timer = setInterval(() => {
      if (count >= maxValue) {
        clearInterval(timer);
      } else {
        setCount((prevCount) => prevCount + 0.01);
      }
    }, 10);
  };

  useEffect(() => {
    updateCount();

    return () => clearInterval(timer);
  }, [count]);

  return (
    <LinearProgressBar
      className="linear-progress-bar_small-wrapper progress-bar"
      size="small"
      value={count}
      max={maxValue}
    />
  );
};

export default ProgressBarIntervalComponent;
