export default {
  testimonials: [
    {
      name: "thoughtworks",
      testimonialText: "pricingPage.testimonials.defaults.thoughtworks.testimonialText",
      personName: "pricingPage.testimonials.defaults.thoughtworks.personName",
      personRole: "pricingPage.testimonials.defaults.thoughtworks.personTitle",
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/v1607704782/remote_mondaycom_static/img/segment-layout-default-images/customer-testimonials/thoughtworks.png",
        alt: "Thoughtworks logo"
      }
    },
    {
      name: "telefonica",
      testimonialText: "pricingPage.testimonials.defaults.telefonica.testimonialText",
      personName: "pricingPage.testimonials.defaults.telefonica.personName",
      personRole: "pricingPage.testimonials.defaults.telefonica.personTitle",
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/v1607704782/remote_mondaycom_static/img/segment-layout-default-images/customer-testimonials/telefonica.png",
        alt: "Telefonica logo"
      }
    },
    {
      name: "genpact",
      testimonialText: "pricingPage.testimonials.defaults.genpact.testimonialText",
      personName: "pricingPage.testimonials.defaults.genpact.personName",
      personRole: "pricingPage.testimonials.defaults.genpact.personTitle",
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/v1607704782/remote_mondaycom_static/img/segment-layout-default-images/customer-testimonials/genpact.png",
        alt: "Genpact logo"
      }
    }
  ],
  autoplay: {
    interval: "10000",
    shouldAutoPlay: true,
    pauseOnHover: true,
    wrapAround: true
  },
  testimonialChangeEventData: {}, // can be used by other components - e.g. in pricing - to modify the manual change event's BB data.
  carouselWidth: "100vw"
};
