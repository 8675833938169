import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import { componentStyles } from "./pricing-features-section.scss";
import FeatureComponent from "./components/feature-component/feature-component";
import DefaultProps from "./default-props";

class PricingFeaturesSection extends PureComponent {
  render() {
    const { section, translate, plans, sectionHeaderID } = this.props;
    return (
      <div className="pricing-features-section">
        <div className="header" role="row" tabIndex={0}>
          <h3 className="section-name" role="rowheader" id={sectionHeaderID}>
            {translate(section.sectionName)}
          </h3>
        </div>
        <div className="features-list" role="rowgroup" aria-labelledby={sectionHeaderID}>
          {section.features.map((feature, index) => {
            const { name, description, id, onVisible } = feature;
            return (
              <div key={name} className="feature" role="row">
                <FeatureComponent
                  key={name}
                  plans={plans}
                  featureName={name}
                  featureDescription={description}
                  featureValuesPerPlan={feature.tiers}
                  onVisible={onVisible}
                  featureId={id}
                  indexInSection={index}
                  sectionName={section.sectionName}
                />
              </div>
            );
          })}
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(PricingFeaturesSection);

PricingFeaturesSection.defaultProps = DefaultProps;
