import { PureComponent } from "react";
import classNames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import { getPlanColor } from "services/pricing/plans-service";
import { getPricingDimensions } from "/services/pricing/pricing-page/pricing-page-display-service";
import PricingActionButton from "segments/desktop/core-components/pricing-action-button/pricing-action-button";

import { stickyTableHeaderComponent } from "./sticky-table-header-component.scss";
import { isDesktopMinisiteSecondaryMenuActive } from "segments/desktop/header/layouts/base-header/base-header-service";

class StickyTableHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldIncreaseStickyTopPosition: false
    };
  }

  stickyScrollingHandler = () => {
    const { isClusterMiniSitePage } = this.props;
    if (!isClusterMiniSitePage) return; // no need to modify the top position if we're not scrolling.
    setTimeout(() => {
      this.setState({
        shouldIncreaseStickyTopPosition: isDesktopMinisiteSecondaryMenuActive()
      });
    }, 0); // we want this to happen after any other setState-s.
  };

  componentDidMount() {
    const { isClusterMiniSitePage } = this.props;
    if (isClusterMiniSitePage) {
      window.addEventListener("scroll", this.stickyScrollingHandler, {
        passive: true
      });
    }
  }

  componentWillUnmount() {
    const { isClusterMiniSitePage } = this.props;
    if (isClusterMiniSitePage) {
      window.removeEventListener("scroll", this.stickyScrollingHandler);
    }
  }

  render() {
    const { plans, translateOrTranslateWithParam, hasBorder } = this.props;
    const { shouldIncreaseStickyTopPosition } = this.state;
    const pricingDimensions = getPricingDimensions(plans);
    const { featuresSection: featuresSectionDimensions } = pricingDimensions;
    const { featureWidth, planWidth } = featuresSectionDimensions;
    const sectionNameStyle = { width: `${featureWidth}%` };
    const planNameStyle = { width: `${planWidth}%` };
    return (
      <div
        className={classNames("sticky-table-header-container", {
          "with-border": hasBorder,
          "scrolling-upwards-minisite": shouldIncreaseStickyTopPosition
        })}
        role="row"
      >
        <div className="pick-your-plan-text" role="columnheader" style={sectionNameStyle} />
        {plans.map((plan) => (
          <div key={plan.tier} className="tier-section" style={planNameStyle}>
            <span className="tier-name" style={{ color: getPlanColor(plan, true) }} role="columnheader">
              {translateOrTranslateWithParam(plan.displayName)}
            </span>
            <span className="tier-button">
              <PricingActionButton
                plan={plan}
                placement="features-table-sticky-header"
                signupButtonText={translateOrTranslateWithParam("signup.tryForFree")}
                contactUsButtonText={translateOrTranslateWithParam("footer.contactUs")}
              />
            </span>
          </div>
        ))}
        <style jsx>{stickyTableHeaderComponent}</style>
      </div>
    );
  }
}

export default withGenericConfig(StickyTableHeaderComponent);
