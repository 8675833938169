import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const productFeaturesTable = css`
  .product-features-table {
    display: flex;
    flex-direction: column;
    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      :global(svg) {
        margin-left: 8px;
        height: 24px;
        width: 24px;
        transition: all 0.5s;
      }
      &.expanded {
        :global(svg) {
          transform: rotate(-180deg);
        }
      }
    }
    .grid {
      display: grid;
      .feature-value, .tier-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        &.tier-header {
          background-color: white;
          position: sticky;
          top: 73px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgb(230, 233, 239);
          z-index: 2;
          .tier-title {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 16px;
          }
        }
      }
      .product-section-header {
        grid-column: 1/-1;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        
        :global(.product-section-logo), :global(.picture-component), :global(img) {
          height: 50px;
          margin: 10px 0;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    /* This table is not meant to be shown on smaller screens. 
            Until an alternative display is designed - if that happens - we just hide it altogether. */
    .product-features-table  {
      display: none;
    }
  }
`;
