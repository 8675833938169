export const ProjectManagementIcon = ({ id }) => (
  <svg width="108" height="105" viewBox="0 0 108 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.066 18.5174C27.066 16.0799 28.3867 13.8276 30.5307 12.6089L50.4255 1.29956C52.5695 0.0808159 55.2109 0.0808198 57.3549 1.29956L77.2498 12.6089C79.3937 13.8276 80.7145 16.0799 80.7145 18.5174L80.7156 45.0751L53.8913 60.3235C44.95 55.2407 36.0086 50.158 27.0671 45.0752C27.0671 36.2226 27.066 27.3702 27.066 18.5174Z"
      fill={`url(#paint0_linear_2481_124011-${id})`}
    />
    <path
      d="M27.0666 45.076C19.2958 49.5186 11.4929 53.9141 3.7059 58.3407C1.56195 59.5595 0.241213 61.8118 0.24121 64.2493L0.241212 86.8679C0.241212 89.3053 1.56194 91.5577 3.7059 92.7764L23.6007 104.086C25.7447 105.304 28.3862 105.304 30.5301 104.086L53.8908 90.821L53.8908 60.3243C44.9473 55.2404 36.007 50.1582 27.0666 45.076Z"
      fill={`url(#paint1_linear_2481_124011-${id})`}
    />
    <path
      d="M104.073 58.3406C106.217 59.5594 107.538 61.8117 107.538 64.2492L107.538 86.8678C107.538 89.3053 106.217 91.5576 104.073 92.7763L84.1785 104.086C82.0346 105.304 79.3931 105.304 77.2491 104.086L53.8891 90.8212L53.8891 60.3245C62.8305 55.2418 71.7719 50.1589 80.7133 45.0762C88.4865 49.5201 96.289 53.9156 104.073 58.3406Z"
      fill={`url(#paint2_linear_2481_124011-${id})`}
    />
    <g styles="mix-blend-mode:multiply">
      <path
        d="M27.066 47.0451C27.066 45.8264 27.7264 44.7002 28.7984 44.0908L52.1579 30.812C53.2299 30.2026 54.5506 30.2026 55.6226 30.812L78.9822 44.0908C80.0541 44.7002 80.7145 45.8263 80.7145 47.0451L80.7145 73.6027C80.7145 74.8215 80.0541 75.9476 78.9822 76.557L55.6226 89.8358C54.5507 90.4452 53.2299 90.4452 52.1579 89.8358L28.7984 76.557C27.7264 75.9476 27.0661 74.8215 27.0661 73.6027L27.066 47.0451Z"
        fill={`url(#paint3_linear_2481_124011-${id})`}
      />
    </g>
    <defs>
      <linearGradient
        id={`paint0_linear_2481_124011-${id}`}
        x1="27.0659"
        y1="14.6157"
        x2="80.2724"
        y2="45.841"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB9000" />
        <stop offset="1" stopColor="#FFCB00" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_2481_124011-${id}`}
        x1="53.8897"
        y1="60.2978"
        x2="0.683283"
        y2="91.523"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB9000" />
        <stop offset="1" stopColor="#FFCB00" />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_2481_124011-${id}`}
        x1="53.8897"
        y1="60.2976"
        x2="107.096"
        y2="91.5224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB9000" />
        <stop offset="1" stopColor="#FFCB00" />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_2481_124011-${id}`}
        x1="53.8903"
        y1="30.355"
        x2="53.8903"
        y2="90.2929"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDAB3D" />
        <stop offset="1" stopColor="#FFCB00" stopOpacity="0.51" />
      </linearGradient>
    </defs>
  </svg>
);
