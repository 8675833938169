import css from "styled-jsx/css";
import { XXS, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const pricingSignupButton = css.global`
  @media (max-width: ${`${XXS}px`}) {
    .pricing-signup-button {
      input.signup-input[name="email"] {
        padding: 6px;
        width: 100%;
        margin-left: -10%;
      }
    }

    .try-cta {
      button.signup-button.ladda-button.xs {
        padding: 8px;
        margin-bottom: 12px;
        margin-left: -10%;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .pricing-signup-button {
      input.signup-input[name="email"] {
        min-width: unset;
      }
    }
  }
`;

export const pricingContactUsButton = css.global`
  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    button.pricing-contact-us-button.xs {
      padding: 8px 32px;
    }
  }
  @media (max-width: ${`${XXS}px`}) {
    button.pricing-contact-us-button.xs {
      padding: 12px 16px;
      margin-bottom: 18px;
    }
  }
`;
