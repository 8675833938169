import {
  getPricingPageSectionsFromClusterConfig,
  getValueFeaturesFromClusterConfig,
  isInClusterTest
} from "services/pricing/pricing-page/clusterized-pricing-page-service";
import { trackEvent } from "services/bigbrain-service";
import { getDefaultPricingPageSections } from "constants/pricing/pricing-page/sections";
import { getFreeTierEssentials } from "../constants/pricing/pricing-page/sections/free_tier_essentials";
import { getDefaultValueFeatures } from "../constants/pricing/pricing-page/value-features";
import { PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED } from "constants/bigbrain-event-types";
import { useContext } from "react";
import { GenericConfigContext } from "/contexts/generic-config-context";

export const getPlanValueFeatures = ({
  hasStudentPlan,
  hasFreeTier,
  abTests,
  cookies,
  clusterId,
  clusterConfig,
  forcePricing
}) => {
  if (clusterId) {
    const pricingPageConfig = clusterConfig?.payments_view?.pricing;
    const valueFeatures = getValueFeaturesFromClusterConfig(pricingPageConfig?.value_features, {
      hasStudentPlan,
      hasFreeTier,
      abTests,
      cookies
    });
    if (valueFeatures && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
      trackEvent(PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED, {
        kind: "user_experience_for_cluster",
        info1: clusterId,
        info2: "payments_view[pricing]",
        data: pricingPageConfig,
        placement: "value_features"
      });
      return valueFeatures;
    }
  }
  // otherwise, returning default values
  return getDefaultValueFeatures({ hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing });
};

export const getPlanFeatures = ({
  hasStudentPlan,
  hasFreeTier,
  abTests,
  cookies,
  clusterId,
  clusterConfig,
  forcePricing
}) => {
  if (clusterId) {
    const pricingPageConfig = clusterConfig?.payments_view?.pricing;
    const pricingPageSections = getPricingPageSectionsFromClusterConfig(pricingPageConfig, {
      abTests,
      cookies,
      hasStudentPlan,
      hasFreeTier
    });
    if (pricingPageSections && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
      trackEvent(PRICING_PAGE_CLUSTERIZED_INFO_PRESENTED, {
        kind: "user_experience_for_cluster",
        info1: clusterId,
        info2: "payments_view[pricing]",
        data: pricingPageConfig,
        placement: "pricing_features"
      });
      return pricingPageSections; // successfully retrieved from cluster config.
    }
  }
  // otherwise - no cluster ID, or user wasn't in cluster test -
  // using default sections list, but possibly modifying it to use the free tier essentials:
  const sections = getDefaultPricingPageSections({ cookies, abTests, hasStudentPlan, hasFreeTier });
  if (hasFreeTier) {
    // pricing pages with free tier have a different essentials list - depending on the free tier version
    const freeTierEssentials = getFreeTierEssentials(abTests, cookies);
    sections.splice(0, 1, freeTierEssentials); // inserting the free tier essentials instead of the regular essentials
  }
  return sections;
};

export const translateProductKeyFallbackToCore = ({ productName, key }) => {
  const { translate } = useContext(GenericConfigContext);
  const name = translate(`pricingPage.products.${productName}.featuresList.${key}`);
  if (name.startsWith('pricingPage.products')) {
    return translate(`pricingPage.products.core.featuresList.${key}`);
  }
  return name;
}
