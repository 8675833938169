export const getPricingPageConfig = () => {
  return {
    template: "LongTemplate",
    title: "monday.com pricing and plans",
    description: "Starting from $8 per user per month, monday.com’s pricing has a plan for everyone.",
    canonicalUrl: "https://monday.com/pricing/",
    withFullstory: true,
    enableHotjar: true,
    fullstoryRecordingFrequency: 100,
    pricingDefaults: {
      defaultCurrency: "usd",
      defaultBillingPeriod: "yearly",
      defaultSelectedPlanName: "standard"
    },
    topbar: {
      preventMenu: false
    },
    socialButtons: {
      show: false
    },
    customersList: [
      {
        name: "outbrain",
        imgSrc: "/static/img/pricing/outbrain.jpg"
      },
      {
        name: "conduit",
        imgSrc: "/static/img/pricing/conduit.jpg"
      },
      {
        name: "wework",
        imgSrc: "/static/img/pricing/wework.jpg"
      },
      {
        name: "spotim",
        imgSrc: "/static/img/pricing/spotim.jpg"
      }
    ],
    tweetIds: ["950446913760956420", "948055055302770689", "948379472830652416", "940538468249333760"]
  };
};
