import { FIELD_TYPES } from "constants/template-field-type";
import productPricingConfig from "segments/desktop/pricing/product-pricing-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Features Table",
  fields: {
    dataNote: {
      type: FIELD_TYPES.CONSTANT,
      label: "Note regarding data: ",
      text: "This component will display either the main pricing page's default features list, or the full features table for the page's cluster, defined via the clusters service."
    },
    productName: {
      label: "Product",
      type: "combo",
      options: Object.keys(productPricingConfig).map((productName) => ({
        value: productName,
        text: productName
      }))
    },
    hideFreeTier: {
      label: "Hide free tier",
      type: FIELD_TYPES.BOOLEAN
    }
  }
};
