import { PureComponent } from "react";
import VisibilitySensor from "react-visibility-sensor";
import classNames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withAbTests } from "/contexts/ab-tests-context";
import { generateOnKeyDownHandler, preventFocusOnMouseDown } from "client/services/accessibility/accessibility-service";
import { getPlans } from "services/pricing/plans-service";
import { getPlanFeatures } from "services/pricing-features-service";
import { trackEvent } from "services/bigbrain-service";
import { SEE_COMPLETE_FEATURES_LIST_CLICKED } from "constants/bigbrain-event-types";
import { TIERS_STICKY_HEADER_BORDER_OFFSET } from "constants/pricing-sizes";
import PricingFeaturesSection from "segments/desktop/core-components/pricing-features-section/pricing-features-section";
import DownArrow from "/static/svg/arrow-down.svg";
import StickyTableHeaderComponent from "./sticky-table-header/sticky-table-header-component";
import { componentStyles } from "./features-table-component.scss";

class FeaturesTableComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldSeeFeaturesList: false,
      hasBorder: false
    };
  }

  onVisibilityChange = (isSeen) => {
    this.setState({
      hasBorder: !isSeen
    });
  };

  getSectionHeaderId = (sectionName) => `featuresSection_${sectionName}`;

  toggleFeaturesList = () => {
    const { clusterId } = this.props;
    const newValue = !this.state.shouldSeeFeaturesList;
    trackEvent(SEE_COMPLETE_FEATURES_LIST_CLICKED, {
      info1: newValue ? "shown" : "hidden",
      placement: "homepage_minisite",
      kind: clusterId
    });
    this.setState({
      shouldSeeFeaturesList: !this.state.shouldSeeFeaturesList
    });
  };

  render() {
    const {
      translateOrTranslateWithParam,
      abTests,
      forcePricing,
      hasFreeTier,
      hasStudentPlan,
      cookies,
      ignoreClusterConfig
    } = this.props;

    const { clusterConfig, clusterId } = ignoreClusterConfig ? { clusterConfig: {}, clusterId: undefined } : this.props;
    const params = { hasStudentPlan, hasFreeTier, abTests, cookies, clusterId, clusterConfig, forcePricing };
    const plans = getPlans(params);
    const tableSections = getPlanFeatures(params);
    const { hasBorder, shouldSeeFeaturesList } = this.state;
    const featuresListButtonTitleText = shouldSeeFeaturesList
      ? "pricingPage.hideCompleteFeaturesList"
      : "pricingPage.seeCompleteFeaturesList";
    return (
      <div className="features-table-component" role="table">
        <div
          className={classNames("see-complete-feature-list-container", { "list-hidden": !shouldSeeFeaturesList })}
          role="button"
          title={translateOrTranslateWithParam(featuresListButtonTitleText)}
          onClick={this.toggleFeaturesList}
          onMouseDown={preventFocusOnMouseDown}
          onKeyDown={generateOnKeyDownHandler(this.toggleFeaturesList)}
          tabIndex={0}
        >
          <h2 className="see-complete-feature-list-text">
            {translateOrTranslateWithParam("pricingPage.completeFeaturesList")}
          </h2>
          <div className="see-complete-feature-list-button" aria-hidden={true}>
            <DownArrow />
          </div>
        </div>
        {shouldSeeFeaturesList && (
          <>
            <StickyTableHeaderComponent plans={plans} hasBorder={hasBorder} />
            <VisibilitySensor offset={{ top: TIERS_STICKY_HEADER_BORDER_OFFSET }} onChange={this.onVisibilityChange}>
              <div className="tiers-sticky-header-visibility-checkpoint" />
            </VisibilitySensor>

            <div className="features-list-sections">
              {tableSections.map((section) => {
                const { sectionName } = section;
                return (
                  <div key={sectionName} className="section-container">
                    <PricingFeaturesSection
                      section={section}
                      plans={plans}
                      sectionHeaderID={this.getSectionHeaderId(sectionName)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withAbTests(withGenericConfig(FeaturesTableComponent));
