import css from "styled-jsx/css";
import { TIER_CARD_PRICE_HEIGHT, CLEAN_FIRST_FOLD_MARGIN } from "constants/pricing-sizes";
import { TIERS } from "constants/pricing";

export const contactUsImage = css`
  .tier-card-contact-us-image-container {
    height: ${`${TIER_CARD_PRICE_HEIGHT}px`};
    display: flex;
    align-items: center;

    .contact-us-image-wrapper {
      margin-left: ${CLEAN_FIRST_FOLD_MARGIN};
      margin-bottom: ${CLEAN_FIRST_FOLD_MARGIN};

      &.basic {
        :global(img) {
          filter: hue-rotate(-66deg);
        }
      }

      &.standard {
        :global(img) {
          filter: invert(1);
        }
      }

      &.pro {
        :global(img) {
          filter: brightness(0.7);
        }
      }

      &.enterprise {
        :global(img) {
          filter: brightness(0.4);
        }
      }
    }
  }
`;
