import css from "styled-jsx/css";

export const usersSelect = css`
  .users-select {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    :global(.billing-users-selection) {
      margin-left: 12px;
      min-width: 190px;
      z-index: 10;
    }
  }
`;
