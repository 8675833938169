import ClapImg from "static/svg/clap.svg";
import ProgressBarIntervalComponent from "../progress-bar-interval-component/progress-bar-interval-component";
import dynamic from "next/dynamic";
import { useContext, useEffect } from "react";
import { trackEvent } from "services/bigbrain-service";
import {
  PRICING_PAGE_PURCHASE_NOW_REDIRECT,
  PRICING_PAGE_PURCHASE_NOW_REDIRECT_FAILURE
} from "constants/bigbrain-event-types";
import { GenericConfigContext } from "contexts/generic-config-context";
import { componentStyles } from "./purchase-now-modal.scss";

const DialogContentContainer = dynamic(() => import("monday-ui-react-core/dist/DialogContentContainer"), {
  ssr: false
});

const PURCHASE_NOW_REDIRECT_SECONDS = 7;

const PurchaseNowModal = ({ isOpen, signupButton }) => {
  const { translate } = useContext(GenericConfigContext);
  useEffect(() => {
    if (isOpen) {
      setTimeout(redirectToSingUp.bind(this, signupButton), PURCHASE_NOW_REDIRECT_SECONDS * 1000);
    }
  }, [isOpen]);

  const redirectToSingUp = () => {
    if (signupButton?.type === "button") {
      trackEvent(PRICING_PAGE_PURCHASE_NOW_REDIRECT);
      signupButton.click();
    } else {
      trackEvent(PRICING_PAGE_PURCHASE_NOW_REDIRECT_FAILURE);
    }
  };

  if (!isOpen) {
    return <></>;
  }
  return (
    <div className="purchase-dialog-wrapper">
      <div className="overlay" />
      <DialogContentContainer type="modal" className="purchase-now-dialog">
        <ClapImg />
        <div className="title">{translate("pricingPage.purchaseNow.modal.title")}</div>
        <div
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: translate("pricingPage.purchaseNow.modal.subtitle") }}
        />
        <ProgressBarIntervalComponent maxValue={PURCHASE_NOW_REDIRECT_SECONDS} className="progress-bar" />
      </DialogContentContainer>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default PurchaseNowModal;
