import { PureComponent } from "react";
import { shouldSeeProductsPricingPage } from "services/ab-tests/monetization-ab-tests-service";
import { withGenericConfig } from "contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import FeaturesTableComponent from "./features-table/features-table-component";
import ProductFeaturesTable  from "./product-features-table/product-features-table";
import { componentStyles } from "./features-table-pricing-component.scss.js";

class FeaturesTablePricingComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.checkIfShouldSeeProductsFeaturesTable();
  }

  componentDidUpdate() {
    this.checkIfShouldSeeProductsFeaturesTable();
  }

  checkIfShouldSeeProductsFeaturesTable() {
    const {
      productName,
      abTests,
      clusterId,
      hasStudentPlan,
      cookies,
      forcePricing,
      cloudfrontViewerCountry,
      isClusterMiniSitePage
    } = this.props;
    this.shouldSeeProductsFeaturesTable =
      productName && (isClusterMiniSitePage ||
      shouldSeeProductsPricingPage({
        abTests,
        clusterId,
        hasStudentPlan,
        cookies,
        forcePricing,
        cloudfrontViewerCountry
      }));
  }

  render() {
    const { ignoreClusterConfig } = this.props;
    return (
      <div className="features-table-pricing-component">
        {this.shouldSeeProductsFeaturesTable ? (
          <ProductFeaturesTable {...this.props} />
        ) : (
          <FeaturesTableComponent ignoreClusterConfig={ignoreClusterConfig} />
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withAbTests(withGenericConfig(FeaturesTablePricingComponent));
