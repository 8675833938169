import css from "styled-jsx/css";
export const PriceWithCurrencyStyle = css`
  .price-with-currency {
    display: flex;
    align-items: flex-end;

    .currency-sign-and-text {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin-right: 2%;
      margin-bottom: 3%;

      .currency-sign {
        font-size: 0.65em;
      }

      .currency-text {
        font-size: 0.2em;
        font-weight: bold;
      }
    }
  }
`;
