import { PureComponent } from "react";
import CarouselGallary from "/components/core/carousel-gallary/carousel-gallary";
import { CUSTOMER_TESTIMONIAL_CHANGED_MANUALLY } from "constants/bigbrain-event-types";
import { trackEvent } from "services/bigbrain-service";
import TestimonialComponent from "./testimonial/testimonial-component";
import { componentStyles } from "./carousel-testimonials-component.scss";
import DefaultProps from "./default-props";

const DEFAULT_AUTOPLAY_INTERVAL = 10000; // 10 seconds

export default class CarouselTestimonialsComponent extends PureComponent {
  onManualTestimonialChange = (index) => {
    const { testimonials, testimonialChangeEventData } = this.props;
    const selected = testimonials[index];
    const { name } = selected;
    trackEvent(CUSTOMER_TESTIMONIAL_CHANGED_MANUALLY, {
      info1: index,
      info2: name,
      ...testimonialChangeEventData
    });
  };

  renderTestimonials = () => {
    const { testimonials } = this.props;
    return testimonials.map((testimonial) => {
      return <TestimonialComponent key={testimonial.name} testimonial={testimonial} />;
    });
  };

  render() {
    const { autoplay, carouselWidth } = this.props;
    const { shouldAutoPlay, interval, pauseOnHover, wrapAround } = autoplay;
    return (
      <div className="carousel-testimonials-component">
        <CarouselGallary
          className="carousel-item"
          autoplay={shouldAutoPlay}
          autoplayInterval={Number(interval) || DEFAULT_AUTOPLAY_INTERVAL}
          pauseOnHover={pauseOnHover}
          wrapAround={wrapAround}
          edgeEasing={"easeBack"}
          slidesToShow={1}
          slideIndex={1}
          cellAlign={"center"}
          cellSpacing={16}
          width={carouselWidth}
          slideWidth={0.7}
          renderCenterLeftControls={() => {}}
          renderCenterRightControls={() => {}}
          heightMode={"max"}
          bottomCenterExtraProps={{
            onButtonClick: this.onManualTestimonialChange
          }}
        >
          {this.renderTestimonials()}
        </CarouselGallary>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

CarouselTestimonialsComponent.defaultProps = DefaultProps;
