import { v4 as uuidv4 } from "uuid";
import { CoreIcon } from "./core-icon";
import { CRMIcon } from "./crm-icon";
import { MarketingIcon } from "./marketing-icon";
import { ProjectManagementIcon } from "./project-management-icon";
import { SoftwareIcon } from "./software-icon";

const productNameToIconMap = {
  core: CoreIcon,
  projectManagement: ProjectManagementIcon,
  software: SoftwareIcon,
  marketing: MarketingIcon,
  crm: CRMIcon
};

export const ProductIcon = ({ product }) => {
  const IconComponent = productNameToIconMap[product];
  return IconComponent ? <IconComponent id={uuidv4()} /> : null;
};
