import { PureComponent } from "react";
import classNames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import { preventFocusOnMouseDown, setSVGTitle } from "client/services/accessibility/accessibility-service";
import { trackEvent } from "services/bigbrain-service";
import PricingInfoIcon from "/static/svg/pricing-info-icon.svg";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import { PRICING_PAGE_FEATURE_LINK_CLICKED } from "constants/bigbrain-event-types";
import {
  HP_SOURCE,
  PRICING_PAGE_FEATURE_INFO_ICON_HOVER,
  PRICING_PAGE_FEATURE_INFO_ICON_EXIT
} from "/constants/bigbrain-event-types";
import { featureInfoWithTooltipStyles } from "./feature-info-with-tooltip.scss";

class FeatureInfoWithTooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tooltipActive: false
    };
  }

  getContainerID = () => {
    const { index, tier } = this.props;
    return `featureInfo_${tier}_${index}`;
  };

  componentDidMount() {
    const { feature, translateOrTranslateWithParam } = this.props;
    const { text } = feature;
    setSVGTitle(`#${this.getContainerID()}`, translateOrTranslateWithParam(text));
  }

  onMouseEnterInfoIcon = () => {
    const { feature, placement, translateOrTranslateWithParam, tier } = this.props;
    const { name } = feature;
    BigBrain("track", PRICING_PAGE_FEATURE_INFO_ICON_HOVER, {
      placement,
      source: HP_SOURCE,
      kind: name,
      info1: translateOrTranslateWithParam(name),
      info2: tier
    });
  };

  onMouseLeaveInfoIcon = () => {
    const { feature, placement, translateOrTranslateWithParam, tier } = this.props;
    const { name } = feature;
    BigBrain("track", PRICING_PAGE_FEATURE_INFO_ICON_EXIT, {
      placement,
      source: HP_SOURCE,
      kind: name,
      info1: translateOrTranslateWithParam(name),
      info2: tier
    });
  };

  onFocus = () => {
    this.onMouseEnterInfoIcon();
    this.setState({
      tooltipActive: true
    });
  };

  onBlur = () => {
    this.onMouseLeaveInfoIcon();
    this.setState({
      tooltipActive: false
    });
  };

  onFeatureLinkClick = (event) => {
    event.preventDefault();
    const { feature, tier } = this.props;
    const { name, link } = feature;
    trackEvent(PRICING_PAGE_FEATURE_LINK_CLICKED, {
      kind: link,
      info1: name,
      info2: tier
    });
    window.open(link, "_blank", "noopener,noreferrer");
  };

  renderInfoIcon = () => {
    const { feature } = this.props;
    const { link } = feature;
    if (!link) {
      return <PricingInfoIcon />;
    }
    return (
      <a tabIndex={0} href={link} onClick={this.onFeatureLinkClick}>
        <PricingInfoIcon />
      </a>
    );
  };

  render() {
    const { feature, featureInfoClassname, translateOrTranslateWithParam } = this.props;
    const { tooltipActive } = this.state;
    return (
      <div
        className={classNames(`feature-info ${featureInfoClassname}`, { "tooltip-active": tooltipActive })}
        onMouseEnter={this.onMouseEnterInfoIcon}
        onMouseLeave={this.onMouseLeaveInfoIcon}
        tabIndex={0}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onMouseDown={preventFocusOnMouseDown}
        aria-label={translateOrTranslateWithParam(feature.text)}
        id={this.getContainerID()}
      >
        {this.renderInfoIcon()}
        <div className="feature-tooltip-container">
          <TooltipComponent theme={DARK_THEME} className={classNames("floating-tooltip")}>
            {translateOrTranslateWithParam(feature.text)}
          </TooltipComponent>
        </div>
        <style jsx>{featureInfoWithTooltipStyles}</style>
      </div>
    );
  }
}

export default withAbTests(withGenericConfig(FeatureInfoWithTooltip));

FeatureInfoWithTooltip.defaultProps = {
  placement: "price-and-features-card",
  featureInfoClassname: ""
};
