import { setCookie } from "services/cookies-service";
import { CURRENCY_COOKIE_NAME } from "constants/cookies";
import { TIERS, SUPPORTED_CURRENCIES } from "constants/pricing";
import { ENGLISH_LOCALE_ID, FRENCH_LOCALE_ID, GERMAN_LOCALE_ID } from "constants/localization";
import { isInClusterTest } from "./pricing/pricing-page/clusterized-pricing-page-service";
import PriceWithCurrency from "../segments/desktop/core-components/pricing/price-with-currency/price-with-currency";

const CURRENCY_SIGN_RIGHT_OF_NUMBER_LOCALES = [FRENCH_LOCALE_ID, GERMAN_LOCALE_ID];

export const USD_CURRENCY = "usd";

export const EURO_COUNTRIES = [
  "ad", // Andorra
  "at", // Austria (EU)
  "be", // Belgium (EU)
  "cy", // Cyprus (EU)
  "ee", // Estonia (EU)
  "fi", // Finland (EU)
  "fr", // France (EU)
  "de", // Germany (EU)
  "gr", // Greece (EU)
  "ie", // Ireland (EU)
  "it", // Italy (EU)
  "lv", // Latvia (EU)
  "lt", // Lithuania (EU)
  "lu", // Luxembourg (EU)
  "mt", // Malta (EU)
  "mc", // Monaco
  "me", // Montenegro
  "nl", // Netherlands (EU)
  "pt", // Portugal (EU)
  "sm", // San Marino
  "sk", // Slovakia (EU)
  "si", // Slovenia (EU)
  "es", // Spain (EU)

  // NEW - starting from Jul 2022
  "al", // Albania
  "bh", // Bahrain
  "by", // Belarus
  "ba", // Bosnia and Herzegovina
  "bg", // Bulgaria (EU)
  "hr", // Croatia (EU)
  "cz", // Czech Republic (EU)
  "dk", // Denmark (EU)
  "eg", // Egypt
  "fo", // Faroe Islands
  "ge", // Georgia
  "gi", // Gibraltar
  "hu", // Hungary (EU)
  "is", // Iceland
  "jo", // Jordan
  "kw", // Kuwait
  "li", // Liechtenstein
  "mk", // Macedonia
  "md", // Moldova
  "ma", // Morocco
  "no", // Norway
  "om", // Oman
  "pl", // Poland (EU)
  "qa", // Qatar
  "ro", // Romania (EU)
  "rs", // Serbia
  "se", // Sweden (EU)
  "ch", // Switzerland
  "sa", // Saudi Arabia
  "tn", // Tunisia
  "tr", // Turkey
  "ug", // Uganda
  "ua", // Ukraine
  "ae", // United Arab Emirates
  "va", // Vatican City
  "ye" // Yemen
];

export const GBP_COUNTRIES = [
  "gb", // United Kingdom
  "je", // Jersey
  "gg", // Guernsey
  "im", // Isle of Man
  "gs", // South Georgia and the South Sandwich Islands
  "uk" // United Kingdom
];

export const CAD_COUNTRIES = [
  "ca" // Canada
];

export const AUD_COUNTRIES = [
  "au" // Australia
];

export const PESO_COUNTRIES = [
  "mx" // Mexico
];
export const REAL_COUNTRIES = [
  "br" // Brazil
];

export const JPY_COUNTRIES = [
  "jp" // Japan
];

const CONTACT_US_THRESHOLD = 50;

export const setCurrencyCookie = function (currencyShortText) {
  setCookie(CURRENCY_COOKIE_NAME, currencyShortText);
};

const getCurrencyFromCountry = (country) => {
  if (country) {
    const countryCode = country.toLowerCase();
    if (EURO_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("eur");
      return "eur";
    }
    if (GBP_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("gbp");
      return "gbp";
    }
    if (CAD_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("cad");
      return "cad";
    }
    if (AUD_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("aud");
      return "aud";
    }
    if (PESO_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("mxn");
      return "mxn";
    }
    if (REAL_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("brl");
      return "brl";
    }
    if (JPY_COUNTRIES.indexOf(countryCode) > -1) {
      setCurrencyCookie("jpy");
      return "jpy";
    }
    //default to usd
    setCurrencyCookie("usd");
    return "usd";
  } else {
    //no country cookie - we return undefined and rely on the default configuration file
    //TODO: log an error? we are server side.
    return undefined;
  }
};

const getCurrency = (forceCurrencyShortText, pricingDefaults, countryCode) => {
  // first check query param exists.
  if (forceCurrencyShortText) {
    return forceCurrencyShortText;
  }

  if (countryCode) {
    return getCurrencyFromCountry(countryCode);
  }
  return pricingDefaults?.defaultCurrency || USD_CURRENCY;
};

export const getCurrencyData = function (currency) {
  const currenciesArray = SUPPORTED_CURRENCIES.filter(function (currencyItem) {
    //find not supported in ie
    return currencyItem.name === currency;
  });
  return currenciesArray[0];
};

export const getCurrencyShortText = function (currency) {
  const currencyData = getCurrencyData(currency);
  if (currencyData.shouldDisplayText) {
    return currencyData.currencyShortText;
  }
  return null;
};

export const getCurrencyName = ({ forceCurrency, pricingDefaults = {}, countryCode }) => {
  const shortCurrencyText = getCurrency(forceCurrency, pricingDefaults, countryCode);
  const currencyArray = SUPPORTED_CURRENCIES.filter(function (supportedCurrency) {
    //find not supported in ie
    return supportedCurrency.currencyShortText.toLowerCase() === shortCurrencyText.toLowerCase();
  });
  return currencyArray[0].name;
};

export const getCurrencySign = function (currency) {
  const currencyData = getCurrencyData(currency);
  return currencyData.currencySign;
};

const shouldShowCurrencyText = (currency) => {
  const lowerCaseCurrency = currency.toLowerCase();

  switch (lowerCaseCurrency) {
    case "cad":
    case "aud":
    case "mxn":
      return true;
    default:
      return false;
  }
};

// For some locales, the currency sign (e.g. $) comes to the right of the price value.
export const getCurrencyWithPriceDisplay = ({
  price,
  currency,
  localeId = ENGLISH_LOCALE_ID,
  hideCurrency = false,
  className
}) => {
  const currencySign = getCurrencySign(currency);
  const shouldShowCurrencyToTheRight = CURRENCY_SIGN_RIGHT_OF_NUMBER_LOCALES.includes(localeId);

  if (!hideCurrency && shouldShowCurrencyText(currency)) {
    return <PriceWithCurrency price={price} currency={currency} currencySign={currencySign} className={className} />;
  }

  return shouldShowCurrencyToTheRight ? `${price} ${currencySign}` : `${currencySign}${price}`;
};

const getContactUsThreshold = ({ clusterConfig, abTests, cookies, forcePricing }) => {
  if (!isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) return CONTACT_US_THRESHOLD;
  return clusterConfig?.payments_view?.pricing?.contact_us_threshold || CONTACT_US_THRESHOLD;
};

export const shouldSeeContactUs = function (tier, usersCount, contactUsThresholdOptions = {}) {
  return (
    tier === TIERS.ENTERPRISE ||
    usersCount.includes("+") ||
    Number(usersCount) >= getContactUsThreshold(contactUsThresholdOptions)
  );
};
