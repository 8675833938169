import css from "styled-jsx/css";
import colors from "styles/colors";

export const bottomCenterPoints = css`
  .bottom-center-point-button {
    color: ${colors["black"]};
    &:focus {
      color: ${colors["brand-blue"]};
    }
  }
`;
