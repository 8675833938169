import { PureComponent, Children, cloneElement } from "react";
import Carousel from "nuka-carousel";
import Button from "/components/core/button/button-component";
import { isFunction } from "services/utils-service";
import BottomCenterPoints from "./decorators/bottom-center-points";

const getSettings = ({ bottomCenterExtraProps }) => {
  return {
    renderBottomCenterControls: ({ currentSlide, slideCount, goToSlide, slidesToScroll }) => {
      return (
        <BottomCenterPoints
          currentSlide={currentSlide}
          slideCount={slideCount}
          goToSlide={goToSlide}
          slidesToScroll={slidesToScroll}
          bottomCenterExtraProps={bottomCenterExtraProps}
        />
      );
    }
  };
};

export default class CarouselGallary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      carouselSlideIndex: props.slideIndex
    };

    this.afterCarouselSlide = this.afterCarouselSlide.bind(this);
  }

  afterCarouselSlide(slideIndex) {
    const { afterSlide } = this.props;
    this.setState({ carouselSlideIndex: slideIndex }, () => {
      if (isFunction(afterSlide)) {
        afterSlide(slideIndex);
      }
    });
  }

  render() {
    const { children, bottomCenterExtraProps } = this.props;
    const { carouselSlideIndex } = this.state;

    return (
      <Carousel {...getSettings({ bottomCenterExtraProps })} {...this.props} afterSlide={this.afterCarouselSlide}>
        {Children.map(children, (child, index) => cloneElement(child, { isCurrentSlide: carouselSlideIndex == index }))}
      </Carousel>
    );
  }
}

CarouselGallary.defaultProps = {
  bottomCenterExtraProps: {}
};
