import classNames from "classnames";
import { PriceWithCurrencyStyle } from "./price-with-currency.scss";

const PriceWithCurrency = ({ price, currency, currencySign, className }) => {
  return (
    <span className="price-with-currency">
      <span className="currency-sign-and-text">
        <div className={classNames("currency-sign", className)}>{currencySign}</div>
        <div className={classNames("currency-text", className)}>{currency.toUpperCase()}</div>
      </span>
      <span className={className}>{price}</span>
      <style jsx>{PriceWithCurrencyStyle}</style>
    </span>
  );
};

export default PriceWithCurrency;
