import { useContext, useCallback, useMemo } from "react";
import Select from "react-select";
import { usersSelect } from "./users-select-component.scss";
import { GenericConfigContext } from "/contexts/generic-config-context";

export const UsersSelect = ({ usersCountArray, updateSelectedUserCountCallback }) => {
  const { translate } = useContext(GenericConfigContext);
  const options = useMemo(
    () =>
      Object.entries(usersCountArray).map(([label, value]) => {
        return {
          label: `${label} ${translate("pricingPage.seats")}`,
          value
        };
      }),
    [usersCountArray, translate]
  );
  const onSelectionChange = useCallback(
    ({ value }) => {
      updateSelectedUserCountCallback(value);
    },
    [updateSelectedUserCountCallback]
  );
  return (
    <div className="users-select">
      {translate("pricingPage.chooseTeamSize")}
      <Select
        name="billing-users-selection"
        className="billing-users-selection"
        isClearable={false}
        isSearchable={false}
        onChange={onSelectionChange}
        defaultValue={options[0]}
        options={options}
        aria-labelledby="usersSelectLabel"
      />
      <style jsx>{usersSelect}</style>
    </div>
  );
};
