import { PureComponent, Fragment } from "react";
import classnames from "classnames";
import sanitizeHtml from "sanitize-html";
import stringUtils from "utils/strings";
import colors from "styles/colors";
import { TIERS, PERIOD_TEXTS, NUM_DEFAULT_DISPLAYED_PLANS } from "constants/pricing";
import { withGenericConfig } from "/contexts/generic-config-context";
import { preventFocusOnMouseDown } from "client/services/accessibility/accessibility-service";
import { getCurrencyWithPriceDisplay, shouldSeeContactUs } from "/services/pricing-service";
import { getPlanColor, getPreviousTierName } from "services/pricing/plans-service";
import { shouldHideCrossedOutPrice, getMaxFreeUsersFromClusterConfig } from "/services/pricing/discounts-service";
import { shouldShowYearlyPrices } from "/services/pricing/periods-service";
import { getMostPopularTierAndText } from "/services/pricing/pricing-page/most-popular-service";
import { getPricingDimensions } from "/services/pricing/pricing-page/pricing-page-display-service";
import { getFreeTierSeatsCountByAbTest, shouldShowPurchaseNow } from "services/ab-tests/monetization-ab-tests-service";

import PricingActionButton from "segments/desktop/core-components/pricing-action-button/pricing-action-button";
import Check from "monday-ui-react-core/dist/icons/Check";
import FeatureInfoWithTooltip from "./feature-info-with-tooltip/feature-info-with-tooltip";
import ContactUsImage from "./contact-us-image/contact-us-image";
import {
  priceAndFeaturesCard,
  totalPrice,
  freeTierTotalPrice,
  tierName,
  pricePerMonth,
  tierFeature,
  priceWithoutDiscountStyle
} from "./price-and-features-card.scss";

const PLAN_ID = "planName_";
const PRICE_CARD_PRICE_CONTAINER_CLASS = "price-card-price-container";

class PriceAndFeaturesCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  hasMoreThanFourPlans = () => {
    const { plans } = this.props;
    return plans.length > NUM_DEFAULT_DISPLAYED_PLANS;
  };

  getClusterConfigOrDefault = () => {
    const { ignoreClusterConfig } = this.props;
    return ignoreClusterConfig ? { clusterConfig: {} } : this.props.clusterConfig;
  };

  getPricePerSeat = (price) => {
    const { userCount, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const freeUsersCount = getMaxFreeUsersFromClusterConfig(clusterConfig, abTests, cookies, forcePricing) || 0;
    if (freeUsersCount >= userCount) return 0;
    return (100 * price) / (userCount - freeUsersCount) / 100;
  };

  renderPricePerSeatWithoutDiscountIfNeeded = () => {
    const { currencyValue, localeId, planPriceWithoutDiscount, planPrice, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    if (shouldHideCrossedOutPrice(clusterConfig, abTests, cookies, forcePricing)) return;
    if (planPrice === planPriceWithoutDiscount) return;
    const priceWithoutDiscount = getCurrencyWithPriceDisplay({
      price: this.getPricePerSeat(planPriceWithoutDiscount).toLocaleString(),
      currency: currencyValue,
      localeId
    });
    return (
      <span className="plan-price-without-discount">
        <span className="plan-price-without-discount-inner">{priceWithoutDiscount}</span>
        <style jsx>{priceWithoutDiscountStyle}</style>
      </span>
    );
  };

  renderPricePerSeat = () => {
    const { currencyValue, localeId, planPrice } = this.props;
    return getCurrencyWithPriceDisplay({
      price: this.getPricePerSeat(planPrice).toLocaleString(),
      currency: currencyValue,
      localeId
    });
  };

  renderFullPrice = () => {
    const { currencyValue, planPrice, localeId } = this.props;
    return getCurrencyWithPriceDisplay({
      price: planPrice.toLocaleString(),
      currency: currencyValue,
      localeId,
      hideCurrency: true
    });
  };

  getTierNameId = (plan) => `${PLAN_ID}_${plan.displayName}`;

  renderMostPopularIfNeeded = () => {
    const { plan, translateOrTranslateWithParam, userCount, plans, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const planColor = getPlanColor(plan, true);
    const isSquareTopText = this.hasMoreThanFourPlans();

    const {
      tier: mostPopularTier,
      text: mostPopularText,
      minimumUsersCount: mostPopularMinimumUsersCount
    } = getMostPopularTierAndText(userCount, plans, clusterConfig, abTests, cookies, forcePricing);
    const { tier } = plan;
    if (mostPopularTier !== tier) return;
    if (mostPopularMinimumUsersCount && userCount < mostPopularMinimumUsersCount) return;

    return (
      <span className={classnames("tier-name-extra", { "with-square-top-text": isSquareTopText })}>
        <span className="arrow" style={{ borderRightColor: planColor }} />
        <span className="extra-text" style={{ backgroundColor: planColor }}>
          {translateOrTranslateWithParam(mostPopularText)}
        </span>
        <style jsx>{tierName}</style>
      </span>
    );
  };
  renderTierName = () => {
    const { plan, translateOrTranslateWithParam } = this.props;
    return (
      <h2 className="price-card-tier-name">
        <span className="tier-name-text" id={this.getTierNameId(plan)}>
          {stringUtils.getCapitilizedString(translateOrTranslateWithParam(plan.displayName))}
        </span>
        {this.renderMostPopularIfNeeded()}
        <style jsx>{tierName}</style>
      </h2>
    );
  };

  renderFreeTierTotalPrice = () => {
    const { translateOrTranslateWithParam, hasStudentPlan } = this.props;
    const billingMessage = hasStudentPlan ? "pricingPage.freeForDurationOfStudies" : "pricingPage.freeForever";
    return (
      <div>
        <div
          className="price-card-free-tier-total-price"
          dangerouslySetInnerHTML={{ __html: translateOrTranslateWithParam(billingMessage) }}
        />
        <style jsx>{freeTierTotalPrice}</style>
      </div>
    );
  };

  getTotalPriceText = () => {
    const { plan, translateOrTranslateWithParam, hasStudentPlan, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const { tier } = plan;
    const isFree = tier === TIERS.FREE;
    const regularBoldText = translateOrTranslateWithParam({
      key: "pricingPage.total.withNumber",
      param: this.renderFullPrice()
    });
    const yearlyBoldText = translateOrTranslateWithParam({
      key: "pricingPage.total.withNumber.yearly",
      param: this.renderFullPrice()
    });
    const freeTierBoldText = translateOrTranslateWithParam({
      key: "pricingPage.upToXSeats",
      param: getFreeTierSeatsCountByAbTest({ cookies, abTests, forcePricing })
    });
    const studentPlanBoldText = translateOrTranslateWithParam({ key: "pricingPage.freeForDurationOfStudies" });
    if (isFree) {
      return hasStudentPlan ? studentPlanBoldText : freeTierBoldText;
    }
    return shouldShowYearlyPrices(clusterConfig, abTests, cookies, forcePricing) ? yearlyBoldText : regularBoldText;
  };

  renderRegularTotalPriceSection = () => {
    const { plan, billingPeriod, translateOrTranslateWithParam } = this.props;
    const { tier } = plan;
    const isFree = tier === TIERS.FREE;
    const boldText = this.getTotalPriceText();
    return (
      <div className="price-card-total-price" tabIndex={0} onMouseDown={preventFocusOnMouseDown}>
        <span className="total-price-text">
          <span
            className="value"
            style={{
              color: colors["black"]
            }}
          >
            {boldText}
          </span>
        </span>
        {!isFree && (
          <span className="billing-message">{translateOrTranslateWithParam(PERIOD_TEXTS[billingPeriod].billed)}</span>
        )}
        <style jsx>{totalPrice}</style>
      </div>
    );
  };

  renderPerSeatPerMonthText = () => {
    const { plan, translateOrTranslateWithParam, hasStudentPlan, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const { tier } = plan;
    const isFree = tier === TIERS.FREE && !hasStudentPlan;
    const firstLine = isFree ? "pricingPage.free" : "pricingPage.seatOver";
    const secondLine = isFree
      ? "pricingPage.forever"
      : shouldShowYearlyPrices(clusterConfig, abTests, cookies, forcePricing)
      ? "pricingPage.year"
      : "pricingPage.month";
    return (
      <span className="per-user-per-month">
        <span>{translateOrTranslateWithParam(firstLine)}</span>
        <span>{translateOrTranslateWithParam(secondLine)}</span>
        <style jsx>{pricePerMonth}</style>
      </span>
    );
  };

  renderPriceSection = () => {
    const { plan } = this.props;
    return (
      <>
        {this.renderPricePerSeatWithoutDiscountIfNeeded()}
        <div
          className="price-card-price-per-month"
          style={{
            color: colors["black"]
          }}
          tabIndex={0}
          onMouseDown={preventFocusOnMouseDown}
        >
          <span className="plan-price" style={{ color: getPlanColor(plan, true) }}>
            {this.renderPricePerSeat()}
          </span>
          {this.renderPerSeatPerMonthText()}
        </div>
        {this.renderRegularTotalPriceSection()}
        <style jsx>{pricePerMonth}</style>
      </>
    );
  };

  getTierFeatureAriaLabel = (feature) => {
    const { plan, translateOrTranslateWithParam } = this.props;
    const { displayName } = plan;
    return `${translateOrTranslateWithParam(displayName)}: ${sanitizeHtml(translateOrTranslateWithParam(feature.name), {
      allowedTags: [],
      allowedAttributes: []
    })}`;
  };

  renderTierFeature = (feature, index) => {
    const { billingPeriod, plan, translateOrTranslateWithParam, showFeatureCheckbox } = this.props;
    const { tier } = plan;

    return (
      <Fragment key={index + "_" + billingPeriod}>
        <div className="price-card-tier-feature">
          {showFeatureCheckbox && <Check />}
          <span
            className="tier-feature-name"
            dangerouslySetInnerHTML={{ __html: translateOrTranslateWithParam(feature.name) }}
            aria-label={this.getTierFeatureAriaLabel(feature)}
            role="region"
            tabIndex={0}
            onMouseDown={preventFocusOnMouseDown}
          />
          {feature.text && (
            <span className="tier-feature-description">
              <FeatureInfoWithTooltip
                feature={feature}
                placement="tier-value-card"
                tier={tier}
                index={index}
                getRegularOrTranslatedText={translateOrTranslateWithParam}
              />
            </span>
          )}
        </div>
        <style jsx>{tierFeature}</style>
      </Fragment>
    );
  };

  shouldIncreaseSeparatorTopMargin = () => {
    // fixes a mobile bug where the separator is higher in the enterprise plan than elsewhere.
    const { plan, userCount, cookies, forcePricing, abTests } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const { tier } = plan;
    return (
      tier === TIERS.ENTERPRISE &&
      !shouldSeeContactUs(TIERS.BASIC, userCount, { clusterConfig, cookies, forcePricing, abTests })
    );
  };

  getRegularOrLineDropText = (originalKey) => {
    return this.hasMoreThanFourPlans() ? `${originalKey}.withLineDrop` : originalKey;
  };

  getTierIncludesText() {
    const { plan, plans, translateOrTranslateWithParam } = this.props;
    const previousTierName = getPreviousTierName(plan.tier, plans);
    return previousTierName == null
      ? translateOrTranslateWithParam({
          key: this.getRegularOrLineDropText("pricingPage.tierCards.planIncludes"),
          param: translateOrTranslateWithParam(plan.displayName)
        })
      : translateOrTranslateWithParam({
          key: this.getRegularOrLineDropText("pricingPage.tierCards.includesPlanPlus"),
          param: translateOrTranslateWithParam(previousTierName)
        });
  }

  onPurchaseNowClick = (e) => {
    const { plan, onPurchaseNowClicked } = this.props;
    const { currentTarget } = e;
    const purchaseNowLinkParent = currentTarget?.closest(`div.${PRICE_CARD_PRICE_CONTAINER_CLASS}`);
    const signupButton = purchaseNowLinkParent?.querySelector("button");
    onPurchaseNowClicked({ tier: plan.tier, target: signupButton });
  };

  render() {
    const {
      plan,
      isChosen,
      translateOrTranslateWithParam,
      features,
      userCount,
      tierDescription,
      signupButtonText,
      contactUsButtonText,
      plans,
      isMobile,
      cookies,
      forcePricing,
      abTests,
      billingPeriod
    } = this.props;
    const clusterConfig = this.getClusterConfigOrDefault();
    const tierIncludesText = this.getTierIncludesText();
    const isContactUsCard = shouldSeeContactUs(plan.tier, userCount, { clusterConfig, cookies, forcePricing, abTests });
    const canPurchaseNow = shouldShowPurchaseNow({ abTests, forcePricing });
    const isFreeOrEnterprise = [TIERS.FREE, TIERS.ENTERPRISE].includes(plan.tier);
    const { tierCards: tierCardsDimensions } = getPricingDimensions(plans);
    const { planWidth } = tierCardsDimensions;
    const tierCardStyle = { width: `${isMobile ? 100 : planWidth}%` };

    return (
      <div className={classnames("price-and-features-card", { chosen: isChosen })} style={tierCardStyle}>
        <div
          className="tier-color-bar"
          style={{ backgroundColor: plan.tier === TIERS.FREE ? "transparent" : getPlanColor(plan, false) }}
        />
        <div className={PRICE_CARD_PRICE_CONTAINER_CLASS} role="region" aria-labelledby={this.getTierNameId(plan)}>
          {this.renderTierName()}
          <div className="pricing-section">
            {isContactUsCard ? <ContactUsImage plan={plan} /> : this.renderPriceSection()}
          </div>
          <div
            className={classnames("try-cta", {
              "try-cta-padding": canPurchaseNow && isFreeOrEnterprise
            })}
          >
            <PricingActionButton
              plan={plan}
              userCount={userCount}
              placement="price-and-features-card"
              signupButtonText={translateOrTranslateWithParam(signupButtonText)}
              contactUsButtonText={translateOrTranslateWithParam(contactUsButtonText)}
              billingPeriod={billingPeriod}
            />
          </div>
          {canPurchaseNow && !isFreeOrEnterprise && (
            <div className="pricing-signup-purchase-now">
              <span>{translateOrTranslateWithParam("signup.or")?.toLowerCase()}</span>
              <a className="purchase-now-link" onClick={this.onPurchaseNowClick}>
                {translateOrTranslateWithParam("pricingPage.purchaseNow")}
              </a>
            </div>
          )}
          <div
            className={classnames("tier-description", { "with-more-plans": this.hasMoreThanFourPlans() })}
            tabIndex={0}
            onMouseDown={preventFocusOnMouseDown}
          >
            {translateOrTranslateWithParam(tierDescription)}
          </div>
        </div>
        <div
          className={classnames("separator", { "with-added-margin": this.shouldIncreaseSeparatorTopMargin() })}
          style={{
            borderColor: colors["wolf"]
          }}
        />
        <div className="tier-includes-container">
          <h3 className="tier-includes-title" dangerouslySetInnerHTML={{ __html: tierIncludesText }} />
          <div className="tier-includes-list">{features.map(this.renderTierFeature)}</div>
        </div>
        <style jsx>{priceAndFeaturesCard}</style>
      </div>
    );
  }
}

export default withGenericConfig(PriceAndFeaturesCard);
