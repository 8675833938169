import css from "styled-jsx/css";
import { PLANS_COMPONENT_WIDTH } from "constants/pricing-sizes";
import { TABLET_QUERY_MAX_WIDTH, XXS, ALTERNATE_MAC_BOOK_PRO_MAX_WIDTH_QUERY } from "/constants/vp-sizes";

export const componentStyles = css.global`
  .prices-and-features-cards-pricing-component {
    width: ${`${PLANS_COMPONENT_WIDTH}px`};
    max-width: calc(100vw - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .top-section {
      width: 100%;
    }

    .cards-section {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .mobile-price-cards {
      display: none;
      flex-direction: column;
      margin-top: 18px;
      align-items: center;
    }

    @media (max-width: ${ALTERNATE_MAC_BOOK_PRO_MAX_WIDTH_QUERY}) {
      .top-section {
        .users-and-period-picker-container {
          margin-bottom: 24px;
        }
      }
    }
    @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
      .top-section {
        .users-and-period-picker-container {
          flex-direction: column;
          align-items: center;
          margin-bottom: 16px;

          .payments-pricing-periods-wrapper {
            width: 100%;

            .payments-pricing-periods {
              margin: 0 auto;
            }
          }
        }
      }

      .cards-section {
        display: none;
      }

      :global(.slider-list) {
        height: 1140px !important;
      }
      :global(.slider-frame) {
        margin-bottom: 80px !important;
      }

      .mobile-price-cards {
        display: flex;

        .price-and-features-card {
          width: 100%;
          height: 1100px;

          .price-card-price-container {
            .tier-description {
              margin-bottom: 32px !important;
              align-items: center;

              &.with-more-plans {
                margin-bottom: 64px !important;
              }
            }
          }

          .price-card-tier-feature {
            margin: 0 10% 14px;
          }
        }
      }
    }

    @media (max-width: ${`${XXS}px`}) {
      :global(.slider-list) {
        height: 1180px !important;
      }
      :global(.slider-frame) {
        margin-bottom: 80px !important;
      }

      .mobile-price-cards {
        display: flex;

        .price-and-features-card {
          width: 100%;
          height: 1160px;

          .price-card-tier-name {
            .tier-name-extra.with-arrow {
              position: relative;
              bottom: 24px;
              right: 30%;

              .arrow {
                display: none;
              }

              .extra-text {
                padding: 0 4px;
              }

              &.with-square-top-text {
                right: unset;
                left: unset;
                position: absolute;
                bottom: -10px;

                .arrow {
                  display: block;
                }

                .extra-text {
                  padding: 4px 8px;
                }
              }
            }
          }

          .price-card-tier-feature {
            margin: 0 10% 14px;
          }
        }
      }
    }
  }
`;
