export const MarketingIcon = ({ id }) => (
  <svg width="108" height="77" viewBox="0 0 108 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.8846 31.8694V74.0978C40.8846 75.3963 41.9317 76.449 43.2234 76.449H64.6875C65.9792 76.449 67.0263 75.3963 67.0263 74.0978V35.6944C67.0263 31.6893 70.2579 28.4425 74.2442 28.4425C78.2306 28.4425 81.4622 31.6893 81.4622 35.6944V74.0978C81.4622 75.3963 82.5093 76.449 83.8009 76.449H105.265C106.557 76.449 107.604 75.3963 107.604 74.0978V31.8694C107.604 14.2684 92.6684 0 74.2443 0C55.8203 0 40.8846 14.2684 40.8846 31.8694Z"
      fill="#CA0C6B"
    />
    <path
      d="M0.307129 31.8694V74.0978C0.307129 75.3963 1.35423 76.449 2.64591 76.449H24.11C25.4017 76.449 26.4488 75.3963 26.4488 74.0978V35.6944C26.4488 31.6893 29.6804 28.4425 33.6667 28.4425C37.6531 28.4425 40.8847 31.6893 40.8847 35.6944V74.0978C40.8847 75.3963 41.9318 76.449 43.2234 76.449H64.6877C65.9794 76.449 67.0265 75.3963 67.0265 74.0978V31.8694C67.0265 14.2684 52.0909 0 33.6668 0C15.2428 0 0.307129 14.2684 0.307129 31.8694Z"
      fill={`url(#paint0_linear_2481_123944-${id})`}
    />
    <path
      d="M0.307129 51.8892H26.4453V74.1224C26.4453 75.4071 25.4086 76.4487 24.1299 76.4487H2.62255C1.34378 76.4487 0.307129 75.4071 0.307129 74.1224V51.8892Z"
      fill="#F8C3DD"
    />
    <path
      d="M40.8883 51.8892H67.0265V74.1224C67.0265 75.4071 65.9898 76.4487 64.711 76.4487H43.2037C41.925 76.4487 40.8883 75.4071 40.8883 74.1224V51.8892Z"
      fill="#F8C3DD"
    />
    <path
      d="M81.469 51.8892H107.607V74.1224C107.607 75.4071 106.571 76.4487 105.292 76.4487H83.7844C82.5057 76.4487 81.469 75.4071 81.469 74.1224V51.8892Z"
      fill="#F8C3DD"
    />
    <defs>
      <linearGradient
        id={`paint0_linear_2481_123944-${id}`}
        x1="-1.86843"
        y1="71.0347"
        x2="106.667"
        y2="57.1665"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF158A" />
        <stop offset="0.765625" stopColor="#FF158A" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </svg>
);
