const PRICING_DIMENSIONS_2_PLANS = {
  tierCards: { planWidth: 40 },
  featuresSection: { featureWidth: 40, planWidth: 30 }
};

const PRICING_DIMENSIONS_3_PLANS = {
  tierCards: { planWidth: 30 },
  featuresSection: { featureWidth: 28, planWidth: 24 }
};

const PRICING_DIMENSIONS_4_PLANS = {
  tierCards: { planWidth: 24 },
  featuresSection: { featureWidth: 24, planWidth: 19 }
};

const PRICING_DIMENSIONS_5_PLANS = {
  tierCards: {
    planWidth: 19,
    topInfoSize: "sm"
  },
  featuresSection: {
    featureWidth: 20,
    planWidth: 16
  }
};
export const getPricingDimensions = (plans) => {
  // calculates the size (percentage) of each plan cell in the pricing page, and the feature cell on the left, and returns it.
  // calculation explanation: the desired result is one where (number of plans)*(plan_width) + feature_width = 100.
  switch (plans.length) {
    case 2: {
      return PRICING_DIMENSIONS_2_PLANS;
    }
    case 3: {
      return PRICING_DIMENSIONS_3_PLANS;
    }
    case 4: {
      return PRICING_DIMENSIONS_4_PLANS;
    }

    default: {
      // 5 tiers
      return PRICING_DIMENSIONS_5_PLANS;
    }
  }
};
