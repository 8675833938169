import { useContext } from "react";
import { GenericConfigContext } from "/contexts/generic-config-context";
import { TIERS } from "constants/pricing";
import classnames from "classnames";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import Button from "components/core/button/button-component";
import { ProductIcon } from "../../../../icons/product-icon";
import { productPriceAndFeatures } from "./product-price-and-features-card.scss";
import PricingInfoIcon from "/static/svg/pricing-info-icon.svg";
import { shouldShowPurchaseNow } from "services/ab-tests/monetization-ab-tests-service";
import { ContactUsIcon } from "./contact-us-icon";
import { ProductPrice } from "./product-price/product-price";
import { withAbTests } from "/contexts/ab-tests-context";
import { getDefaultContactUsPageLink } from "/services/pricing/pricing-page/contact-us-link-service";
import { getTierTranslationKey } from "/services/pricing/plans-service";
import Colors from "styles/colors";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";

const maxNoTouchSeats = 50;
const CARD_CLASS = "card";

const ProductPriceAndFeatures = ({
  productName,
  period,
  tier,
  color,
  seats,
  isMostPopular,
  abTests,
  onPurchaseNowClicked,
  hideFreeForever
}) => {
  const { name: tierName, features } = tier;
  const { translate, cookies, forcePricing } = useContext(GenericConfigContext);
  const shouldShowPrices = tierName !== TIERS.ENTERPRISE && seats < maxNoTouchSeats;
  const canPurchaseNow = shouldShowPurchaseNow({ abTests, forcePricing });
  const isFreeOrEnterprise = [TIERS.FREE, TIERS.ENTERPRISE].includes(tierName);

  const onPurchaseNowClick = (e) => {
    const { currentTarget } = e;
    const purchaseNowLinkParent = currentTarget.closest(`div.${CARD_CLASS}`);
    const signupButton = purchaseNowLinkParent.querySelector("button");
    onPurchaseNowClicked({ tier, target: signupButton });
  };

  return (
    <div className={classnames(CARD_CLASS, { "is-most-popular": isMostPopular })}>
      <div className="icons">
        <div className="icon" style={{ "--product-color": color }}>
          <ProductIcon product={productName} />
        </div>
      </div>
      <div className="tier-title-container">
        <div className="tier-title">
          <div className="product-and-tier">
            <div className="tier">
              {translate(getTierTranslationKey(tierName, { hasStudentPlan: false, cookies, abTests, forcePricing }))}
            </div>
            {productName !== "core" && <div>{translate(`pricingPage.products.${productName}.title`)}</div>}
          </div>
          {isMostPopular && (
            <div className="most-popular-badge-container">
              <div className="most-popular-badge">{translate("pricingPage.mostPopular")}</div>
            </div>
          )}
        </div>
      </div>
      {shouldShowPrices ? (
        <ProductPrice
          product={productName}
          period={period}
          seats={seats}
          tierName={tierName}
          hideFreeForever={hideFreeForever}
        />
      ) : (
        <div className="contact">
          <ContactUsIcon />
        </div>
      )}
      <div className={classnames("cta-button", { "try-cta-padding": canPurchaseNow && isFreeOrEnterprise })}>
        {shouldShowPrices ? (
          <SignupFormComponent
            className="signup-form"
            ctaText={translate("signup.tryForFree")}
            buttonSize="xs"
            useCtaText
            buttonSpinnerColor={Colors[BRAND_BLACK_TEXT_COLOR]}
            pricingDetails={{ tier: tierName, userCount: seats, billingPeriod: period, product: productName }}
          />
        ) : (
          <Button
            className="cta-button"
            type="button"
            size="xs"
            onClickCallback={() => {
              window.location.href = getDefaultContactUsPageLink(abTests);
            }}
          >
            {translate("footer.contactSales")}
          </Button>
        )}
      </div>
      {canPurchaseNow && !isFreeOrEnterprise && (
        <div className="pricing-signup-purchase-now">
          <span>{translate("signup.or")?.toLowerCase()}</span>
          <a className="purchase-now-link" onClick={onPurchaseNowClick}>
            {translate("pricingPage.purchaseNow")}
          </a>
        </div>
      )}
      <div className="description">
        {translate(`pricingPage.products.${productName}.tiers.${tier.name}.description`)}
      </div>
      <div className="horizontal-line" />
      {features.map((feature) => (
        <div className="feature">
          <span
            dangerouslySetInnerHTML={{
              __html: translate(`pricingPage.products.${productName}.tiers.${tierName}.features.${feature}`)
            }}
          />
          <div className="icon">
            <div className="tooltip">
              <TooltipComponent theme={DARK_THEME} className="floating-tooltip">
                {translate(`pricingPage.products.${productName}.tiers.${tierName}.features.${feature}.description`)}
              </TooltipComponent>
            </div>
            <PricingInfoIcon />
          </div>
        </div>
      ))}
      <style jsx>{productPriceAndFeatures}</style>
      <style jsx>{`
        .tier-title {
          .most-popular-badge,
          .most-popular-badge::before {
            background-color: ${color};
          }
        }
        .card {
          .cta-button :global(.signup-button):hover,
          .cta-button :global(.cta-button):hover {
            border-color: ${color};
            background-color: ${color};
            filter: none;
          }
        }
      `}</style>
    </div>
  );
};

export default withAbTests(ProductPriceAndFeatures);
