import colors from "styles/colors";
import { TIERS } from "constants/pricing";

export default {
  core: {
    color: colors["product-core"],
    mostPopularTierBySeat: { 0: TIERS.STANDARD, 25: TIERS.PRO, 100: TIERS.ENTERPRISE },
    tiers: [
      {
        name: TIERS.FREE,
        features: ["upTo2TeamMembers", "upTo1000Items", "500MbStorage"]
      },
      {
        name: TIERS.BASIC,
        features: [
          "unlimitedFreeViewers",
          "unlimitedItems",
          "unlimitedBoard",
          "200plusTemplates",
          "20plusColumnTypes",
          "iosAndAndroidApps",
          "dashboardBasedOn1Board"
        ]
      },
      {
        name: TIERS.STANDARD,
        features: [
          "timelineAndGanttViews",
          "calendarView",
          "guestAccess",
          "automations250",
          "integrations250",
          "dashboardCombined5boards"
        ]
      },
      {
        name: TIERS.PRO,
        features: [
          "privateBoardsAndWorkdocs",
          "chartView",
          "timeTracking",
          "formulaColumn",
          "automations25K",
          "integrations25K",
          "dashboardCombined10boards"
        ]
      },
      {
        name: TIERS.ENTERPRISE,
        features: [
          "enterpriseAutomationsAndIntegrations",
          "multiLevelPermissions",
          "enterpriseSecurityAndGovernance",
          "advancedReportingAndAnalytics",
          "tailoredOnboarding",
          "premiumSupport",
          "dashboardCombined50boards"
        ]
      }
    ],
    featuresList: [
      {
        sectionName: "essentials",
        features: [
          {
            featureName: "maximumNumberOfSeats",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "items",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "fileStorage",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.storage_free",
              [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
              [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
            }
          },
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          },
          {
            featureName: "unlimitedBoards",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedDocs",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "over20ColumnTypes",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "200plusTemplates",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "iosAndAndroidApps",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedMessagesUpdates",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
            }
          },
          {
            featureName: "automations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
            }
          },
          {
            featureName: "unlimitedFreeViewers",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "collaboration",
        features: [
          {
            featureName: "embeddedDocuments",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "whiteboardCollaboration",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "updatesSection",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "zoomIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "guestAccess",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "productivity",
        features: [
          {
            featureName: "ShareableForms",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
              [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
            }
          },
          {
            featureName: "customNotifications",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "customFields",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "premiumIntegrations",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeTracking",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "formulaColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dependencyColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "workload",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "viewsAndReporting",
        features: [
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          },
          {
            featureName: "dashboards",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
              [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
            }
          },
          {
            featureName: "kanbanView",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeLineView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "calendarView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mapView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "chartView",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "support",
        features: [
          {
            featureName: "selfServeKnowledgeBase",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "247CustomerSupport",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dailyLiveWebinars",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dedicatedCsManager",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "99SLA",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "securityAndPrivacy",
        features: [
          {
            featureName: "soc2compliance",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "twoFactorAuth",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardAndDocs",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAuth",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sso",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "hippaCompliance",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrationPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ipRestrictions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "contentDirectory",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "administrationAndControl",
        features: [
          {
            featureName: "boardAdmins",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "scimProvisioning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "auditLog",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sessionManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "panicMode",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateWorkspaces",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedAccountPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "enterpriseReportingAndAnalytics",
        features: [
          {
            featureName: "workPerformanceInsights",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dashboardEmailNotification",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "pivotAnalysisAndReports",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      }
    ]
  },
  marketing: {
    mostPopularTierBySeat: { 0: TIERS.STANDARD, 25: TIERS.PRO, 100: TIERS.ENTERPRISE },
    color: colors["product-marketing"],
    tiers: [
      {
        name: TIERS.FREE,
        features: ["upTo2Members", "upTo1000Items", "simpleMarketingTaskManagement", "basicFilterAndSearch"]
      },
      {
        name: TIERS.BASIC,
        features: [
          "unlimitedFreeViewers",
          "unlimitedItems",
          "contentAndCreativeAssetLibraries",
          "digitalAssetManagementIntegrations",
          "creativeRequestForms",
          "basicActivityTracking",
          "marketingAnalytics",
          "iosAndAndroidApps"
        ]
      },
      {
        name: TIERS.STANDARD,
        features: [
          "contentCalendarView",
          "campaignGanttView",
          "readyMadeDashboards",
          "exclusiveMarketingIntegrations",
          "customAutomations250",
          "integrations250",
          "marketingAnalytics"
        ]
      },
      {
        name: TIERS.PRO,
        features: [
          "privateBoardsAndWorkDocs",
          "annotationsMultipleFileTypes",
          "versionControl",
          "campaignGanttWithDeps",
          "creativeCloudPlugin",
          "shareableBriefs",
          "customAutomations25k",
          "integrations25k",
          "marketingAnalytics"
        ]
      },
      {
        name: TIERS.ENTERPRISE,
        features: [
          "enterpriseAutomationsAndIntegrations",
          "campaignApprovalAdvanced",
          "enterpriseSecurityAndGovernance",
          "10TBstorage",
          "salesforceAndJiraIntegration",
          "marketingAnalytics",
          "premiumSupport"
        ]
      }
    ],
    featuresList: [
      {
        sectionName: "essentials",
        features: [
          {
            featureName: "30PlusReadyMadeTemplates",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "upTo2TeamMembers",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "items",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "contentCalendarView",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "eventManagement",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "annotationsMultipleFileTypes",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "versionControl",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
            }
          },
          {
            featureName: "automations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
            }
          },
          {
            featureName: "iosAndAndroidApps",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedBoards",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedDocs",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "over20ColumnTypes",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "200plusTemplates",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedFreeViewers",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "shareableRequestForms",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardsAndWorkDocs",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "dynamicForms",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          }
        ]
      },
      {
        sectionName: "campaignManagement",
        features: [
          {
            featureName: "contentAndCreativeAssetLibraries",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAds",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "facebookAds",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "campaignGanttWithDeps",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "marketingAnalytics",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "campaignApprovalAdvanced",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "digitalAssetManagement",
        features: [
          {
            featureName: "contentAndBrandManagement",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "embeddedDocuments",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "whiteboardCollaboration",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "guestAccess",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "creativeCloudPlugin",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "1TBStorage",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "5TBStorage",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "assetApproval",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
        ]
      },
      {
        sectionName: "productivity",
        features: [
          {
            featureName: "customNotifications",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeTracking",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "formulaColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dependencyColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "premiumIntegrations",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "viewsAndReporting",
        features: [
          {
            featureName: "dashboards",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
              [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
            }
          },
          {
            featureName: "kanbanView",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeLineView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "calendarView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mapView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "chartView",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "workload",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "support",
        features: [
          {
            featureName: "selfServeKnowledgeBase",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "247CustomerSupport",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dailyLiveWebinars",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dedicatedCsManager",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "99SLA",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "securityAndPrivacy",
        features: [
          {
            featureName: "soc2compliance",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "twoFactorAuth",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardAndDocs",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAuth",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sso",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "hippaCompliance",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrationPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ipRestrictions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "contentDirectory",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "administrationAndControl",
        features: [
          {
            featureName: "boardAdmins",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "scimProvisioning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "auditLog",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sessionManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "panicMode",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateWorkspaces",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedAccountPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "enterpriseReportingAndAnalytics",
        features: [
          {
            featureName: "workPerformanceInsights",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dashboardEmailNotification",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "pivotAnalysisAndReports",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      }
    ]
  },
  crm: {
    color: colors["crm-green"],
    mostPopularTierBySeat: { 0: TIERS.STANDARD, 25: TIERS.PRO, 100: TIERS.ENTERPRISE },
    tiers: [
      {
        name: TIERS.FREE,
        features: ["upTo2TeamMembers", "upTo1000Contacts", "webForms"]
      },
      {
        name: TIERS.BASIC,
        features: [
          "customizablePipelines",
          "unlimitedContacts",
          "unlimitedBoard",
          "leadManagement",
          "iosAndAndroidApps",
          "unlimitedFreeViewers",
          "dashboardCombined1board"
        ]
      },
      {
        name: "standard",
        features: [
          "accountContactAndDealManagement",
          "twoWayEmailIntegration",
          "activityManagement",
          "quotesAndInvoices",
          "mergeDuplicateData",
          "automations",
          "integrations",
          "dashboardCombined5boards"
        ]
      },
      {
        name: TIERS.PRO,
        features: [
          "salesForeCasting",
          "emailTemplates",
          "emailTracking",
          "googleCalendarSync",
          "salesAnalytics",
          "customEmailSignatures",
          "automations",
          "integrations",
          "dashboardCombined10boards"
        ]
      },
      {
        name: TIERS.ENTERPRISE,
        features: [
          "leadScoring",
          "teamGoals",
          "advancedAnalytics",
          "accountManagement",
          "documentsForSales",
          "enterpriseAutomationsAndIntegrations",
          "enterpriseSecurityAndGovernance",
          "multiLevelPermissions",
          "hippaCompliance"
        ]
      }
    ],
    featuresList: [
      {
        sectionName: "essentials",
        features: [
          {
            featureName: "contactsDatabase",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "maximumNumberOfSeats",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "webFormsToCaptureLeads",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "basicLeadContractAndDealManagement",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedCustomizablePipelines",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedBoards",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedDocs",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "over20ColumnTypes",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "200plusTemplates",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "iosAndAndroidApps",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedFreeViewers",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "leadManagement",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "accountAndContractManagement",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dealManagement",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "items",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "fileStorage",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.storage_free",
              [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
              [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
            }
          },
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          }
        ]
      },
      {
        sectionName: "customerRelationship",
        features: [
          {
            featureName: "twoWayEmailIntegration",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "activityManagement",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "quotesAndInvoices",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "emailTemplates",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "emailTracking",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "googleCalendarSync",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "customizableEmailSignatures",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "productivity",
        features: [
          {
            featureName: "customNotifications",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mergeDuplicateData",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "integrations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
            }
          },
          {
            featureName: "automations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
            }
          },
          {
            featureName: "docusignIntegration",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "airCallIntegration",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "pandaDocIntegration",
            tierColumnValues: { [TIERS.STANDARD]: true, [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "mailChimpIntegration",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "hubSpotIntegration",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "facebookAdsIntegration",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "timeTracking",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "formulaColumn",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "salesForceIntegration",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "leadScoring",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "duplicateWarning",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "insightsAndReporting",
        features: [
          {
            featureName: "salesForecasting",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "salesAnalytics",
            tierColumnValues: { [TIERS.PRO]: true, [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "advancedAnalytics",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "teamGoals",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "dashboards",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
              [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
            }
          }
        ]
      },
      {
        sectionName: "postSalesManagement",
        features: [
          {
            featureName: "accountManagement",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "clientProjects",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "collectionTracking",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "salesOperations",
        features: [
          {
            featureName: "headcountPlanning",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "salesTeamOnboarding",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "salesCollateral",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "documentsForSales",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "legalAndSecurityRequests",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "collaboration",
        features: [
          {
            featureName: "embeddedDocuments",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "whiteboardCollaboration",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "updatesSection",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "zoomIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "guestAccess",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "viewsAndReporting",
        features: [
          {
            featureName: "kanbanView",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeLineView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "calendarView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mapView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "chartView",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "workload",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "support",
        features: [
          {
            featureName: "selfServeKnowledgeBase",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "247CustomerSupport",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dailyLiveWebinars",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dedicatedCsManager",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          },
          {
            featureName: "99SLA",
            tierColumnValues: { [TIERS.ENTERPRISE]: true }
          }
        ]
      },
      {
        sectionName: "securityAndPrivacy",
        features: [
          {
            featureName: "soc2compliance",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "twoFactorAuth",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardAndDocs",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAuth",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sso",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "hippaCompliance",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrationPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ipRestrictions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "contentDirectory",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "administrationAndControl",
        features: [
          {
            featureName: "boardAdmins",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "scimProvisioning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "auditLog",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sessionManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "panicMode",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateWorkspaces",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedAccountPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "enterpriseReportingAndAnalytics",
        features: [
          {
            featureName: "workPerformanceInsights",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dashboardEmailNotification",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "pivotAnalysisAndReports",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      }
    ]
  },
  projectManagement: {
    color: colors["product-project-management"],
    mostPopularTierBySeat: { 0: TIERS.STANDARD, 25: TIERS.PRO, 100: TIERS.ENTERPRISE },
    tiers: [
      {
        name: TIERS.FREE,
        features: ["upTo2Members", "upTo1000Tasks", "brainstormingInitiativesAndPlanning"]
      },
      {
        name: TIERS.BASIC,
        features: [
          "freeViewingAccess",
          "unlimitedTasks",
          "projectRequests",
          "centralizedProjectCommunication",
          "agileProjectsWithKanbanView",
          "dashboardCombined1board",
          "mobileApps"
        ]
      },
      {
        name: TIERS.STANDARD,
        features: [
          "calendarViewAndIntegration",
          "ganttChart",
          "guestAccess",
          "milestones",
          "simpleOverviewAcrossProjects",
          "taskRollup",
          "projectAutomations",
          "integrations250",
          "dashboardCombined5boards"
        ]
      },
      {
        name: TIERS.PRO,
        features: [
          "privateBoardsAndWorkDocs",
          "dependencies",
          "resourceAllocation",
          "criticalPath",
          "baseline",
          "projectAutomations",
          "integrations25k",
          "dashboardCombined10boards"
        ]
      },
      {
        name: TIERS.ENTERPRISE,
        features: [
          "enterpriseAutomationsAndIntegrations",
          "enterpriseSecurityAndGovernance",
          "advancedReportingAndAnalytics",
          "multiLevelPermissions",
          "portfolioManagementAndDashboard",
          "projectsLifeCycle",
          "dashboardCombined50boards"
        ]
      }
    ],
    featuresList: [
      {
        sectionName: "essentials",
        features: [
          {
            featureName: "maximumNumberOfSeats",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "items",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          },
          {
            featureName: "fileStorage",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.storage_free",
              [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
              [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
            }
          },
          {
            featureName: "importFromExcel",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedBoards",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedDocs",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "over20ColumnTypes",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "simpleProjectOverview",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "manageProjectsTasksAndSubtasks",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "freeViewingAccess",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "centralizedProjectCommunication",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "milestones",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "simpleOverviewAcrossProjects",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "taskRollup",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "projectAndTaskFilters",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "criticalPath",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "resourceAllocation",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "effortManagement",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "budgetAndCostManagement",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "baseline",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dependencies",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "projectApprovals",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "portfolioManagementAndDashboard",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "projectAndPortfolioRiskManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "trackingProgressAcrossPortfolioAndPrograms",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "collaboration",
        features: [
          {
            featureName: "embeddedDocuments",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "whiteboardCollaboration",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "updatesSection",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "zoomIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "guestAccess",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "productivity",
        features: [
          {
            featureName: "ShareableForms",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
              [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
            }
          },
          {
            featureName: "customNotifications",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
            }
          },
          {
            featureName: "automations",
            tierColumnValues: {
              [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
              [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
            }
          },
          {
            featureName: "microsoftTeamsApp",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "slackIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeTracking",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "formulaColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedIntegrationsWithJiraServerSalesforceAndMore",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "viewsAndReporting",
        features: [
          {
            featureName: "dashboards",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
              [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
            }
          },
          {
            featureName: "agileProjectsWithKanbanView",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeLineView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "calendarViewAndIntegrations",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mapView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "chartView",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "workload",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "goalTracking",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "plannedVsActualAnalysis",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "enterpriseCostManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
        ]
      },
      {
        sectionName: "support",
        features: [
          {
            featureName: "selfServeKnowledgeBase",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "247CustomerSupport",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dailyLiveWebinars",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dedicatedCsManager",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "99SLA",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "securityAndPrivacy",
        features: [
          {
            featureName: "soc2compliance",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "twoFactorAuth",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardAndDocs",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAuth",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sso",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "hippaCompliance",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrationPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ipRestrictions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "contentDirectory",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "administrationAndControl",
        features: [
          {
            featureName: "boardAdmins",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "scimProvisioning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "auditLog",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sessionManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "panicMode",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateWorkspaces",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedAccountPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "enterpriseReportingAndAnalytics",
        features: [
          {
            featureName: "workPerformanceInsights",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dashboardEmailNotification",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "pivotAnalysisAndReports",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      }
    ]
  },
  software: {
    color: colors["dev-orange"],
    mostPopularTierBySeat: { 0: TIERS.STANDARD, 25: TIERS.PRO, 100: TIERS.ENTERPRISE },
    tiers: [
      {
        name: TIERS.FREE,
        features: ["upTo2TeamMembers", "upToItems", "simpleTaskManagement"]
      },
      {
        name: TIERS.BASIC,
        features: [
          "unlimitedViewers",
          "unlimitedItems",
          "templatesForDev",
          "wikiAndDocumentations",
          "dashboardCombined1board",
          "mobileApps"
        ]
      },
      {
        name: TIERS.STANDARD,
        features: [
          "ganttChart",
          "guestAccess",
          "sprintManagement",
          "roadmapPlanning",
          "githubApp",
          "automations",
          "integrations",
          "dashboardCombined5boards"
        ]
      },
      {
        name: TIERS.PRO,
        features: [
          "privateBoards",
          "timeTracking",
          "agileReporting",
          "roadmapWithDeps",
          "customerFeedbackAndRequests",
          "automations",
          "integrations",
          "dashboardCombined10boards"
        ]
      },
      {
        name: TIERS.ENTERPRISE,
        features: [
          "enterpriseAutomationsAndIntegrations",
          "enterpriseSecurityAndGovernance",
          "advancedReportingAndAnalytics",
          "multiLevelPermissions",
          "tailoredOnboarding",
          "premiumSupport",
          "dashboardCombined50boards"
        ]
      }
    ],
    featuresList: [
      {
        sectionName: "essentials",
        features: [
          {
            featureName: "maximumNumberOfSeats",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "items",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
              [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
              [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
              [TIERS.PRO]: "pricingPage.features.plans.unlimited",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
            }
          },
          {
            featureName: "fileStorage",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.storage_free",
              [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
              [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
            }
          },
          {
            featureName: "wikiAndDocumentations",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sprintManagement",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "kanbanView",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "backlog",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "bugTracking",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "unlimitedBoards",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "iosAndAndroidApps",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "freeViewingAccess",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "gitHubApp",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "gitlabIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "jiraIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "slackIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "microsoftTeamsApp",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "planning",
        features: [
          {
            featureName: "customerFeedbackAndRequests",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "featuresPrioritization",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "roadmapWithDependencies",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "documentSpecsAndProductRequirementDocs",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "productPlanning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "featureRequestsAndReleaseManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
        ]
      },
      {
        sectionName: "collaboration",
        features: [
          {
            featureName: "unlimitedMessagesUpdates",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "embeddedDocuments",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "updatesSection",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "whiteboardCollaboration",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "miroIntegration",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "figmaIntegration",
            tierColumnValues: {
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "zoomIntegration",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "productivity",
        features: [
          {
            featureName: "customNotifications",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "manageDifferentIssueTypes",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ShareableForms",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
              [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
            }
          },
          {
            featureName: "shareableBoardsWithExternalStakeholders",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timeTracking",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "formulaColumn",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "viewsAndReporting",
        features: [
          {
            featureName: "dashboards",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
              [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
              [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
            }
          },
          {
            featureName: "activityLog",
            tierColumnValues: {
              [TIERS.FREE]: "pricingPage.features.plans.one_week",
              [TIERS.BASIC]: "pricingPage.features.plans.one_week",
              [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
              [TIERS.PRO]: "pricingPage.features.plans.one_year",
              [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
            }
          },
          {
            featureName: "timeLineView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "calendarViewAndIntegrations",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "mapView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "timelineAndGanttViews",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "chartView",
            tierColumnValues: {
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "workload",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "agileReporting",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "support",
        features: [
          {
            featureName: "selfServeKnowledgeBase",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "247CustomerSupport",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dailyLiveWebinars",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dedicatedCsManager",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "99SLA",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "securityAndPrivacy",
        features: [
          {
            featureName: "soc2compliance",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "twoFactorAuth",
            tierColumnValues: {
              [TIERS.FREE]: true,
              [TIERS.BASIC]: true,
              [TIERS.STANDARD]: true,
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateBoardAndDocs",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "googleAuth",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sso",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "hippaCompliance",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "integrationPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "contentDirectory",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "ipRestrictions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "administrationAndControl",
        features: [
          {
            featureName: "boardAdmins",
            tierColumnValues: {
              [TIERS.PRO]: true,
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "scimProvisioning",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "auditLog",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "sessionManagement",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "panicMode",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "privateWorkspaces",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "advancedAccountPermissions",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      },
      {
        sectionName: "enterpriseReportingAndAnalytics",
        features: [
          {
            featureName: "workPerformanceInsights",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "dashboardEmailNotification",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          },
          {
            featureName: "pivotAnalysisAndReports",
            tierColumnValues: {
              [TIERS.ENTERPRISE]: true
            }
          }
        ]
      }
    ]
  }
};
