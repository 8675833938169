import css from "styled-jsx/css";

export const periodSelect = css`
  .periodSelect {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #333333;

    .period {
      cursor: pointer;
      &.selected {
        cursor: default;
        color: #4353ff;
      }
    }
  }
`;
