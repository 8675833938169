import css from "styled-jsx/css";
import colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH, SMALL_MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .testimonial-component {
    background-color: ${colors["white"]};
    display: flex;
    flex-direction: column;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }

    .testimonial-image-container {
      width: 98%;
      max-width: 290px;
      height: 100px;
      border: 2px solid;
      border-color: ${colors["wolf"]};
      border-radius: 8px;
      background-color: ${colors["white"]};
      position: relative;
      top: 42px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;

      .testimonial-image {
        padding: 4px;
        width: auto;
        height: auto;
        max-width: 250px;
        max-height: 90px;
      }

      @media (max-width: ${SMALL_MOBILE_QUERY_MAX_WIDTH}) {
        .testimonial-image {
          transform: scale(0.7);
        }
      }
    }

    .testimonial-text-container {
      background-color: ${colors["riverstone"]};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 280px;
      border-radius: 8px;

      .testimonial-text {
        color: ${colors["mud"]};
        padding: 0 10%;
        text-align: center;
        margin-top: 36px;
        font-size: 1.375rem;
      }

      .testimonial-person-container {
        margin-top: 32px;
        margin-bottom: 24px;
        font-size: 1.125rem;
        text-align: center;

        .testimonial-person-name {
          font-weight: bold;
        }

        .separator {
          margin: 0 4px;
        }
      }

      @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
        .testimonial-text,
        .testimonial-person-container {
          font-size: 1rem;
        }

        .testimonial-text {
          margin-top: 60px;
        }
      }
      @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
        .testimonial-text,
        .testimonial-person-container {
          font-size: 0.875rem;
        }

        .testimonial-text {
          margin-top: 60px;
        }
      }
      @media (max-width: ${SMALL_MOBILE_QUERY_MAX_WIDTH}) {
        .testimonial-text,
        .testimonial-person-container {
          font-size: 0.8125rem;
        }

        .testimonial-text {
          margin-top: 60px;
        }
      }
    }
  }
`;
