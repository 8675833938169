export const ContactUsIcon = () => (
  <svg width="70" height="64" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7533 50.8641C21.726 50.4148 22.832 50.3383 23.8582 50.6493C27.2353 51.6726 30.9105 52.2372 34.7712 52.2372C52.445 52.2372 65.1989 40.7866 65.1989 28.4008C65.1989 16.0091 52.445 4.56139 34.7712 4.56139C17.0971 4.56139 4.34418 16.0091 4.34659 28.4C4.34659 34.7429 7.55133 40.6962 13.1472 45.1352C14.7487 46.4057 15.237 48.6134 14.3188 50.4321L12.0774 54.871L20.7533 50.8641ZM2.60656 64.0005L10.4323 48.5018C3.99148 43.3925 4.40334e-07 36.2732 4.40334e-07 28.4008C-0.00302297 12.8511 15.5637 0.250488 34.7712 0.250488C53.9787 0.250488 69.5455 12.8511 69.5455 28.4008C69.5455 43.9445 53.9787 56.5481 34.7712 56.5481C30.4834 56.5481 26.38 55.9211 22.5881 54.7721L2.60656 64.0005Z"
      fill="currentColor"
    />
  </svg>
);
