import css from "styled-jsx/css";
import colors from "/styles/colors";

export const pricingPeriods = css`
  .payments-pricing-periods-wrapper {
    display: flex;
    margin-top: 24px;

    .payments-pricing-periods {
      font-size: 1rem;
    }
  }
`;
export const periodItem = css`
  .period-item-container {
    display: inline-block;
    &:not(:last-child) {
      border-right: 1px solid;
      border-color: ${colors["black"]};
    }
    padding: 0 8px;

    .period-item {
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease;
      text-transform: capitalize;

      &:focus.clicked &:hover:not(.selected) {
        color: ${colors["brand-dark-blue"]};
      }

      &.selected {
        color: ${colors["brand-dark-blue"]};
      }
    }

    &:focus > .period-item {
      outline: initial;
    }
  }
`;

export const usersAndPeriodSelectionComponent = css.global`
  .users-and-period-picker-container {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    margin-bottom: 32px;

    .users-select-container {
      display: flex;
      align-items: center;

      .users-select-label {
        font-size: 0.875rem;
        margin-right: 12px;
      }

      .users-select-component {
        font-size: 0.875rem;
        min-width: 190px;
        height: 40px;
        box-shadow: none;
        border-radius: 4px;
        .Select-control {
          background-color: ${colors.white};
          border-color: ${colors["wolf-gray"]};
          color: ${colors.asphalt};
        }

        &.selected {
          .Select-control {
            background-color: ${colors.riverstone};
            border-color: ${colors.asphalt};
            color: ${colors.mud};
          }
        }

        .Select-arrow-zone {
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 14px;
          align-items: center;
          justify-content: center;
          .Select-arrow {
            height: 8px;
            width: 8px;
            position: relative;
            border: none;
            border-left: 1px solid ${colors.asphalt};
            border-bottom: 1px solid ${colors.asphalt};
            transform: rotate(-45deg) translateY(-50%);
            transition: transform 0.1s ease, top 0.1s ease;
          }
        }

        &.is-open > .Select-control .Select-arrow {
          transform: rotate(-225deg) translateY(50%);
          top: 25%;
        }
      }
    }
  }
`;
