import dynamic from "next/dynamic";

const PricesAndFeaturesCardsPricingComponent = require("./prices-and-features-cards-pricing-component").default;

import PricesAndFeaturesCardsPricingConfig from "./prices-and-features-cards-pricing-config";
import PricesAndFeaturesCardsPricingAdvancedConfig from "./prices-and-features-cards-pricing-advanced-config";

export default {
  PricesAndFeaturesCardsPricingComponent,
  PricesAndFeaturesCardsPricingConfig,
  PricesAndFeaturesCardsPricingAdvancedConfig
};
