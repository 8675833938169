import css from "styled-jsx/css";
import colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { CLEAN_FIRST_FOLD_MARGIN } from "constants/pricing-sizes";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const productPriceAndFeatures = css`
  .horizontal-line {
    display: block;
    width: 100%;
    border-bottom: 2px solid #c5c7d0;
    margin: 24px 0;
  }

  .card {
    min-width: 0;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    padding: 16px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .icons {
      height: 45px;
      display: flex;
      .icon {
        flex-shrink: 0;
        border: solid 1px;
        border-radius: 8px;
        width: 42px;
        height: 42px;
        padding: 4px;
        margin-right: 8px;
        color: #292f4c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: var(--product-color);
      }
    }

    .tier-title-container {
      height: 50px;
      align-self: stretch;
    }

    .tier-title {
      margin: 8px 0;
      font-size: 1.238rem;
      color: #333333;
      letter-spacing: -0.36px;
      display: flex;
      align-items: center;
      position: relative;
      max-width: calc(100% - 80px);
      .product-and-tier {
        & > div {
          float: left;
        }
      }

      .tier {
        font-weight: bold;
        margin-right: 4px;
      }

      .most-popular-badge-container {
        flex-grow: 1;
      }

      .most-popular-badge {
        font-weight: 700;
        font-size: 0.8125rem;
        line-height: 16px;
        border-radius: 4px;
        width: 66px;
        padding: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 16px;
        z-index: 2;
        color: white;
        background-color: ${colors["product-core"]};
        &::before {
          background-color: ${colors["product-core"]};
          z-index: 1;
          content: "";
          position: absolute;
          transform: rotate(45deg);
          width: 14px;
          height: 14px;
          left: -7px;
          top: calc(50% - 7px);
          display: block;
        }
      }
    }
    &.is-most-popular {
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
      .cta-button :global(.signup-button),
      .cta-button :global(.cta-button) {
        color: black;
      }
    }

    .contact {
      height: 140px;
      padding: 16px 0;
      & > div {
        font-weight: 600;
      }
    }

    .cta-button {
      &.try-cta-padding {
        padding-bottom: 64px;
      }
      padding: 0 0 16px 0;
      :global(.signup-button),
      :global(.cta-button) {
        background-color: transparent;
        border: 1px solid black;
        color: black;
        letter-spacing: 0.4px;
        :global(*) {
          font-weight: bold;
        }
      }
    }

    .pricing-signup-purchase-now {
      display: flex;
      gap: 5px;
      margin-bottom: ${CLEAN_FIRST_FOLD_MARGIN};
      height: 20px;
    }

    .purchase-now-link {
      font-weight: bold;
      text-decoration-line: underline;
    }

    .description {
      font-size: 0.875rem;
      line-height: 24px;
      height: 70px;
      letter-spacing: -0.36px;
      color: #676879;
    }

    .feature {
      font-size: 0.844rem;
      margin-bottom: 24px;
      display: flex;
      align-self: stretch;
      justify-content: space-between;
      .icon {
        position: relative;
        margin-left: 6px;
        .tooltip {
          z-index: ${MODAL_BACKGROUND};
          display: none;
          opacity: 0;
          position: absolute;
          bottom: calc(100% + 16px);
          transform: translateX(-50%);
          transition: opacity 0.2s ease;
          width: 300px;
          :global(.floating-tooltip) {
            position: relative;
            white-space: normal;
            text-align: left;
          }
        }
        &:hover {
          .tooltip {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .cta-button {
      :global(.signup-form) {
        :global(input) {
          min-width: unset;
        }
      }
    }
  }
`;
