import css from "styled-jsx/css";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const productFeaturesTableFeature = css`
  .feature-name {
    justify-content: space-between;
    font-weight: 400;
    font-size: 0.844rem;
    .icon {
      position: relative;
      margin-left: 6px;
      .tooltip {
        z-index: ${MODAL_BACKGROUND};
        display: none;
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 16px);
        transform: translateX(-50%);
        transition: opacity 0.2s ease;
        width: 300px;
        :global(.floating-tooltip) {
          position: relative;
          white-space: normal;
          text-align: left;
        }
      }
      &:hover {
        .tooltip {
          display: block;
          opacity: 1;
        }
      }
    }
  }
  .feature-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    color: #676879;
  }
  .feature-value {
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px 0;
  }

  .check-circle {
    :global(path) {
      fill: #676879;
    }
  }
`;
